import {
  Component,
  Input,
  Type,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { TableRow } from './table-row.interface';
import { NzLayoutModule } from 'ng-zorro-antd/layout';
import { NzTableModule } from 'ng-zorro-antd/table';
import { TranslateModule } from '@ngx-translate/core';
import { CommonModule } from '@angular/common';
import { TooltipComponent } from '../tooltip/tooltip.component';

@Component({
  standalone: true,
  selector: 'app-reusable-table-expand',
  templateUrl: './reusable-table-expand.component.html',
  styleUrls: ['./reusable-table-expand.component.css'],
  imports: [
    NzLayoutModule,
    NzTableModule,
    TranslateModule,
    CommonModule,
    TooltipComponent,
  ],
})
export class ReusableTableExpandComponent<T extends TableRow> {
  @Input() title: string = '';
  @Input() columns: string[] = [];
  @Input() rowFields: string[] = [];
  @Input() data?: T[] = [];
}
