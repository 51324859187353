import {Component, ViewChild} from '@angular/core';
import {ICoding} from 'src/app/interfaces/ICoding';
import {AddPatientService} from 'src/app/services/addpatient.service';
import {PersonalInfoComponent} from './personal-info/personal-info.component';
import {IdentifiersComponent} from './identifiers/identifiers.component';
import {AddressComponent} from './address/address.component';
import {IAddress} from 'src/app/interfaces/IAddress';
import {Router} from '@angular/router';
import {IAddPatient} from 'src/app/interfaces/Add/IAddPatient';
import {FormBuilder, FormGroup} from '@angular/forms';
import {InsuranceComponent} from './insurance/insurance.component';
import {MatStepper} from '@angular/material/stepper';
import {IPatientDocument} from 'src/app/interfaces/IPatientDocument';
import {IDoctor} from '../../../interfaces/IDoctor';
import Swal from 'sweetalert2';
import {Subject} from 'rxjs';
import {HttpErrorResponse} from '@angular/common/http';
import {NzSelectOptionInterface} from 'ng-zorro-antd/select';

@Component({
  selector: 'app-register-patient',
  templateUrl: './register-patient.component.html',
  styleUrls: ['./register-patient.component.css'],
})
export class RegisterPatientComponent {
  // Form
  patientForm: FormGroup;
  formSubmitted: boolean = false;

  // Loading
  private destroy$ = new Subject<void>();
  isLoading = false;

  // Value Sets
  public bloodTypesList: ICoding[] = [];
  public gendersList: ICoding[] = [];
  public countriesList: ICoding[] = [];
  public doctorList: IDoctor[] = [];

  public doctors: NzSelectOptionInterface[] = [];

  // Step Components
  @ViewChild(PersonalInfoComponent) personalInfoComp!: PersonalInfoComponent;
  @ViewChild(IdentifiersComponent) identifiersComp!: IdentifiersComponent;
  @ViewChild(AddressComponent) addressComp!: AddressComponent;
  @ViewChild(InsuranceComponent) insuranceComp!: InsuranceComponent;

  constructor(
    private readonly addPatient: AddPatientService,
    private readonly router: Router,
    private formBuilder: FormBuilder
  ) {
    this.patientForm = this.formBuilder.group({
      personalInfo: this.formBuilder.group({
        // Define personal info form controls and validators here
      }),
      identifiers: this.formBuilder.group({
        // Define identifiers form controls and validators here
      }),
      address: this.formBuilder.group({
        // Define address form controls and validators here
      }),
      insurance: this.formBuilder.group({
        // Define insurance form controls and validators here
      }),
    });
  }

  ngOnInit() {
    this.isLoading = false;
    this.addPatient.getPatientsDropDown().subscribe({
      next: (response) => {
        this.bloodTypesList = response?.data?.bloodTypes;
        this.gendersList = response?.data?.genders;
        this.countriesList = response?.data?.countries;
        this.doctorList = response?.data?.doctors;

        this.doctors = this.doctorList.map((d) => {
          return { label: d.fullName, value: d.id };
        });
      },
      error: (err) => {
        console.log(err);
      },
    });
  }

  async savePatientForm() {
    this.formSubmitted = true;
    this.isLoading = true;

    const patientObj: IAddPatient = {};
    if (this?.personalInfoComp?.personalInfoForm?.value['firstName'])
      patientObj.firstName =
        this.personalInfoComp.personalInfoForm.value['firstName'];
    if (this?.personalInfoComp?.personalInfoForm?.value['lastName'])
      patientObj.lastName =
        this.personalInfoComp.personalInfoForm.value['lastName'];
    if (this?.personalInfoComp?.personalInfoForm?.value['dateOfBirth'])
      patientObj.dateOfBirth =
        this.personalInfoComp.personalInfoForm.value['dateOfBirth'];
    if (this?.personalInfoComp?.personalInfoForm?.value['phone'])
      patientObj.phone = this.personalInfoComp.personalInfoForm.value['phone'];
    if (this?.personalInfoComp?.personalInfoForm?.value['email'])
      patientObj.email = this.personalInfoComp.personalInfoForm.value['email'];
    if (this?.personalInfoComp?.personalInfoForm?.value['gender'])
      patientObj.genderId =
        this.personalInfoComp.personalInfoForm.value['gender'];
    if (this?.personalInfoComp?.personalInfoForm?.value['bloodType'])
      patientObj.bloodTypeId =
        this.personalInfoComp.personalInfoForm.value['bloodType'];
    if (this?.personalInfoComp?.personalInfoForm?.value['height'])
      patientObj.height =
        this.personalInfoComp.personalInfoForm.value['height'];
    if (this?.personalInfoComp?.personalInfoForm?.value['weight'])
      patientObj.weight =
        this.personalInfoComp.personalInfoForm.value['weight'];
    if (this?.personalInfoComp?.personalInfoForm?.value['doctor'])
      patientObj.doctorId =
        this.personalInfoComp.personalInfoForm.value['doctor'];

    const addressObj: IAddress = {};
    if (this?.addressComp?.addressForm?.value['street'])
      addressObj.street = this.addressComp.addressForm.value['street'];

    if (this?.addressComp?.addressForm?.value['streetNumber'])
      addressObj.streetNumber =
        this.addressComp.addressForm.value['streetNumber'];
    if (this?.addressComp?.addressForm?.value['town'])
      addressObj.town = this.addressComp.addressForm.value['town'];
    if (this?.addressComp?.addressForm?.value['postcode'])
      addressObj.postCode = this.addressComp.addressForm.value['postcode'];
    if (this?.addressComp?.addressForm?.value['district'])
      addressObj.district = this.addressComp.addressForm.value['district'];
    if (this?.addressComp?.addressForm?.value['country'])
      addressObj.countryId = this.addressComp.addressForm.value['country'];
    if (this?.addressComp?.addressForm?.value['apartmentNumber'])
      addressObj.apartmentNumber =
        this.addressComp.addressForm.value['apartmentNumber'];

    if (Object.keys(addressObj).length !== 0)
      patientObj.addAddressDto = addressObj;

    const documentsObj: IPatientDocument[] = [];

    if (
      this?.identifiersComp?.identifiersForm?.value['nationalId'] &&
      this?.identifiersComp?.identifiersForm?.value['nationalIdCountry']
    ) {
      documentsObj.push({
        documentTypeId: 0,
        documentNumber:
          this.identifiersComp.identifiersForm.value['nationalId'],
        documentCountryIssuedId:
          this.identifiersComp.identifiersForm.value['nationalIdCountry'],
      });
    }

    if (
      this?.identifiersComp?.identifiersForm?.value['passport'] &&
      this?.identifiersComp?.identifiersForm?.value['passportCountry']
    ) {
      documentsObj.push({
        documentTypeId: 1,
        documentNumber: this.identifiersComp.identifiersForm.value['passport'],
        documentCountryIssuedId:
          this.identifiersComp.identifiersForm.value['passportCountry'],
      });
    }

    if (this?.identifiersComp?.identifiersForm?.value['arc']) {
      documentsObj.push({
        documentTypeId: 2,
        documentNumber: this?.identifiersComp.identifiersForm.value['arc'],
      });
    }

    if (documentsObj.length !== 0) patientObj.documents = documentsObj;

    this.addPatient.savePatient(patientObj).subscribe({
      next: (response) => {

        console.log(response.data)
        this.uploadFileOnUser(response.data.id);

        this.isLoading = false;

        Swal.fire({
          text: 'Patient added successfully!',
          toast: true,
          position: 'bottom-end',
          showCancelButton: false,
          showConfirmButton: false,
          color: 'white',
          background: '#0d9488',
          timer: 3000,
        });
        this.router.navigate(['/ehr/allPatients']);
      },
      error: (err) => {
        this.isLoading = false;

        let errorMessage = 'Unable to add patient!';

        //is an HttpErrorResponse and contains the error message
        if (err instanceof HttpErrorResponse && err.error?.message) {
          errorMessage += ` Error: ${err.error.message}`;
        }

        this.isLoading = false;
        Swal.fire({
          text: errorMessage,
          toast: true,
          position: 'bottom-end',
          showCancelButton: false,
          showConfirmButton: false,
          color: 'white',
          background: '#ff6969',
          timer: 3000,
        });
        console.log(errorMessage);
      },
    });
  }

  nextStep(stepper: MatStepper, stepIncrement: number): void {
    const currentStep = stepper.selectedIndex;
    const nextStep = currentStep + stepIncrement;

    // Get the form of the current step
    let currentStepForm: FormGroup | undefined;
    switch (currentStep) {
      case 0:
        currentStepForm = this.personalInfoComp?.personalInfoForm;
        break;
      case 1:
        currentStepForm = this.identifiersComp?.identifiersForm;
        break;
      case 2:
        currentStepForm = this.addressComp?.addressForm;
        break;
      case 3:
        currentStepForm = this.insuranceComp?.insuranceForm;
        break;
      default:
        break;
    }

    // Mark the form controls as touched
    if (currentStepForm) {
      Object.keys(currentStepForm.controls).forEach((controlName) => {
        const control = currentStepForm?.get(controlName);
        control?.markAsTouched();
      });

      // Check if the form is valid before proceeding to the next step
      if (nextStep >= 0 && nextStep < stepper._steps.length) {
        if (currentStepForm.valid) {
          stepper.selectedIndex = nextStep;
        } else {
          // Handle validation errors
          if (stepIncrement === -1 && stepper.selectedIndex != 0) {
            //Go back on second and after
            stepper.selectedIndex = stepper.selectedIndex + stepIncrement;
          } else {
            //Show error message on validation
            if (stepper.selectedIndex != 1) {
              Swal.fire({
                text: 'Fill all the required data to continue!',
                toast: true,
                position: 'bottom-end',
                showCancelButton: false,
                showConfirmButton: false,
                color: 'white',
                background: '#ff6969',
                timer: 3000,
              });
            } else {
              //Identifiers Error
              Swal.fire({
                text: 'Please fill all the required fields!',
                toast: true,
                position: 'bottom-end',
                showCancelButton: false,
                showConfirmButton: false,
                color: 'white',
                background: '#ff6969',
                timer: 3000,
              });
            }
          }
          console.log('Form contains validation errors.');
        }
      } else if (nextStep < 0 && currentStep > 0) {
        stepper.selectedIndex = nextStep;
      } else {
        // Handle validation errors or edge cases
        console.log('Form contains validation errors or invalid step.');
      }
    }
  }

   uploadProfilePicture(patientId :number, profilePicture: File) {
    const formData = new FormData();
    formData.append('PatientId', patientId.toString());
    formData.append('PatientPicture', profilePicture);

     this.addPatient.uploadProfilePicture(formData).subscribe({
         next: () => {

           Swal.fire({
             text: 'Patient profile picture added successfully!',
             toast: true,
             position: 'bottom-end',
             showCancelButton: false,
             showConfirmButton: false,
             color: 'white',
             background: '#0d9488',
             timer: 3000,
           });
         },
       error: (err) => {
         let errorMessage = 'Unable to add patient profile picture!';

         //is an HttpErrorResponse and contains the error message
         if (err instanceof HttpErrorResponse && err.error?.message) {
           errorMessage += ` Error: ${err.error.message}`;
         }
         Swal.fire({
           text: errorMessage,
           toast: true,
           position: 'bottom-end',
           showCancelButton: false,
           showConfirmButton: false,
           color: 'white',
           background: '#ff6969',
           timer: 3000,
         });
         console.log(errorMessage);
       },
     });
  }


  async uploadFileOnUser(patientId : number) {
      try {
        const pictureToUpload = this.addPatient.getProfilePicture();
          if (pictureToUpload != null)
          {
            const extension = this.getProfilePictureFileExtension();
            const file = new File([pictureToUpload],`ProfilePicture.${extension}`)
            this.uploadProfilePicture(patientId,file);
            this.addPatient.setProfilePicture(null);
            console.log('Profile picture uploaded successfully');
          }
        }
      catch (error) {
        console.error('Failed to upload profile picture:', error);
      }
  }

  getProfilePictureFileExtension(): string | null {
    const profilePictureBlob = this.addPatient.getProfilePicture();
    if (profilePictureBlob != null)
    {
      if (profilePictureBlob instanceof Blob) {
        const mimeType = profilePictureBlob.type;
        return mimeType.split('/')[1];
      }
    }
    return null;
  }


  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
