import { Component, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { NzUploadChangeParam, NzUploadFile } from 'ng-zorro-antd/upload';
import { IServiceResponse } from 'src/app/interfaces/IServiceResponse';
import { CDAService } from 'src/app/services/cda.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-import',
  templateUrl: './import.component.html',
  styleUrls: ['./import.component.css'],
})
export class ImportComponent {
  isLoading: boolean = false;

  inputValue: string =
    'raw.githubusercontent.com/IHE/connectathon-artifacts/main/profile_test_data/PCC/IPS-QEDm/ips-cda/cda-eumfh-43-155-ips.xml';

  prefix: string = 'Https://';

  fileList: NzUploadFile[] = [];

  constructor(
    private readonly cdaService: CDAService,
    private readonly router: Router
  ) {}

  importByLink() {
    this.isLoading = true;
    this.cdaService
      .uploadXMLlink(encodeURIComponent(this.prefix + this.inputValue))
      .subscribe({
        next: (res: IServiceResponse) => {
          this.isLoading = false;
          this.router.navigate(['/ehr/profile'], {
            queryParams: {
              patientID: res.message,
            },
          });
        },
        error: (err) => {
          console.log(err);
          Swal.fire({
            text: 'Unable to import CDA!',
            toast: true,
            position: 'bottom-end',
            showCancelButton: false,
            showConfirmButton: false,
            color: 'white',
            background: '#ff6969',
            timer: 3000,
          });

          this.isLoading = false;
        },
      });
  }

  beforeUpload = (file: NzUploadFile): boolean => {
    this.fileList = [file];
    return false; // Prevent automatic upload
  };

  handleChange(): any {
    if (this.fileList.length > 0) {
      this.cdaService.uploadXMLfile(this.fileList[0] as any).subscribe({
        next: (res: IServiceResponse) => {
          this.isLoading = false;
          this.router.navigate(['/ehr/profile'], {
            queryParams: {
              patientID: res.message,
            },
          });
        },
        error: (err) => {
          console.log(err);
          Swal.fire({
            text: 'Unable to import CDA!',
            toast: true,
            position: 'bottom-end',
            showCancelButton: false,
            showConfirmButton: false,
            color: 'white',
            background: '#ff6969',
            timer: 3000,
          });

          this.isLoading = false;
        },
      });
      return {
        file: {
          /* ... */
        },
        fileList: [
          /* ... */
        ],
        event: {
          /* ... */
        },
      };
    }
  }
}
