import { ChangeDetectorRef, Component } from '@angular/core';
import { Store } from '@ngrx/store';
import { IModule } from 'src/app/interfaces/IModule';
import { IPatientDetailed } from 'src/app/interfaces/Detailed/IPatientDetailed';
import { selectMenu } from 'src/app/selectors/menu.selector';
import { PatientService } from 'src/app/services/patient.service';
import { EpisodeOfCareService } from 'src/app/services/episodeOfCare.service';
import { Router } from '@angular/router';
import { paths } from 'src/component-paths/component-paths';

@Component({
  selector: 'app-episode-of-care',
  templateUrl: './episode-of-care.component.html',
  styleUrls: ['./episode-of-care.component.css'],
})
export class EpisodeOfCareComponent {
  public menuData?: IModule;
  public selectedOption?: number;
  public isCollapsed: boolean = false;
  public isPreview: boolean = false;

  componentPaths = paths;

  constructor(
    private readonly patientService: PatientService,
    private readonly store: Store,
    private readonly cdr: ChangeDetectorRef,
    private readonly eocService: EpisodeOfCareService,
    private readonly router: Router
  ) {}

  public patientID: string = '';

  public patientInfo?: IPatientDetailed;

  toggleCollapsed(): void {
    this.isCollapsed = !this.isCollapsed;
  }

  togglePreview(value: boolean) {
    this.isPreview = value;
    this.cdr.detectChanges();
  }

  updateSelectedOption(option: number) {
    this.selectedOption = option;
  }

  clickItem(m: IModule) {
    if (m.path != this.componentPaths.pilotStudy) {
      this.updateSelectedOption(m.id);
      this.scrollToSection(m.path);
    }
  }

  scrollToSection(sectionId: string): void {
    const element = document.getElementById(sectionId);
    if (element) {
      const offset = element.offsetTop - window.innerHeight * 0.08; // Calculate the offset (6vh)
      window.scroll({
        top: offset,
        behavior: 'smooth',
      });
    }
  }

  activeSubmenu(): string {
    return (
      this.menuData?.childModules?.find((d) =>
        d.childModules.find((x) => x.id === this.selectedOption)
      )?.path ||
      this.menuData?.childModules?.find((d) => d.id === this.selectedOption)
        ?.path ||
      ''
    );
  }
  getSubmenu(menu: string): IModule[] | undefined {
    return this.menuData?.childModules?.find((d) => d.path === menu)
      ?.childModules;
  }

  ngOnInit() {
    this.isPreview = false;
    this.patientID = this.patientService.patientIDsignal();
    this.patientService.patientLoadingSub.next(true);

    this.patientService.getPatientByID(this.patientID).subscribe({
      next: (response) => {
        this.patientService.patientInfoSub.next(response?.data!);
        this.patientService.patientLoadingSub.next(true);
        this.patientInfo = response?.data; // Assuming data is the property you want

        this.store.select(selectMenu).subscribe((data) => {
          let profileModule = data
            ?.find((d) => d.path == 'allPatients')
            ?.childModules?.find((d) => d.path === 'profile')!;

          // Remove Episode of Care TAB (ID: 25)
          profileModule = {
            ...profileModule,
            childModules:
              profileModule?.childModules?.filter((c) => c.id != 25) || [],
          };

          // If not imported
          // Remove IPS preview TAB (ID: 26)
          if (!this.patientInfo?.isImported) {
            profileModule = {
              ...profileModule,
              childModules:
                profileModule?.childModules?.filter((c) => c.id != 26) || [],
            };
          }

          // If not female
          // Remove Pregnancy TAB (ID: 21)
          if (this.patientInfo?.gender?.toLowerCase() != 'female') {
            profileModule = {
              ...profileModule,
              childModules:
                profileModule?.childModules?.filter((c) => c.id != 21) || [],
            };
          }

          // If not inpatient
          // Remove Daily Monitoring TAB (ID: 28)
          // Remove Etiology TAB (ID: 45)
          // Remove Complication TAB (ID: 46)
          // Remove Tracheostomy 2 TAB (ID: 49)
          if (!this.isInpatientView()) {
            profileModule = {
              ...profileModule,
              childModules:
                profileModule?.childModules?.filter(
                  (c) => c.id != 28 && c.id != 45 && c.id != 46
                ) || [],
            };
          }

          this.menuData = profileModule;
        });

        // if the first option is pilot study (44)
        // have tracheostomy2 (49) as preselcted
        if (this.menuData?.childModules?.at(0)?.id == 44) {
          this.selectedOption = 49;
        } else if (
          this.menuData?.childModules?.at(0)?.childModules.length == 1
        ) {
          this.selectedOption = this.menuData?.childModules?.[0].id;
        } else {
          this.selectedOption =
            this.menuData?.childModules?.at(0)?.childModules?.at(0)?.id ||
            this.menuData?.childModules?.[0].id ||
            this.menuData?.id ||
            0;
        }

        this.patientService.patientLoadingSub.next(false);
      },
      error: (err) => {
        console.error('Error fetching patient information:', err);
      },
    });
  }

  isInpatientView() {
    return (
      !(
        this.eocService.currentEocId() === null ||
        this.eocService.currentEocId() === ''
      ) && this.router.url.includes('inPatientView')
    );
  }
}
