import { createReducer, on } from '@ngrx/store';
import * as menuActions from '../actions/menu.actions';
import { IModule } from '../interfaces/IModule';

export interface MenuState {
  menu: IModule[];
}

export const initialState: MenuState = {
  menu: [],
};

export const menuReducer = createReducer(
  initialState,
  on(menuActions.setMenu, (state, { menu }) => ({ ...state, menu }))
);
