import { Component, ViewChild } from '@angular/core';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { NzModalModule, NzModalRef, NzModalService } from 'ng-zorro-antd/modal';
import { AllergyService } from 'src/app/services/allergy.service';
import {
  FormBuilder,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { FlexLayoutModule } from '@angular/flex-layout';
import { NzSelectModule, NzSelectOptionInterface } from 'ng-zorro-antd/select';
import { IAllergyDropdown } from 'src/app/interfaces/Dropdown/IAllergyDropdown';
import { CommonModule } from '@angular/common';
import { IAddAllergy } from 'src/app/interfaces/Add/IAddAllergy';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { IReactionDetailed } from 'src/app/interfaces/Detailed/IReactionDetailed';
import { v4 as uuidv4 } from 'uuid';
import { NzTableModule } from 'ng-zorro-antd/table';
import { NzLayoutModule } from 'ng-zorro-antd/layout';
import { TooltipComponent } from '../../../../../../../common/tooltip/tooltip.component';
import { NzIconModule } from 'ng-zorro-antd/icon';
import Swal from 'sweetalert2';
import { Subject, takeUntil } from 'rxjs';
import { NzNoAnimationModule } from 'ng-zorro-antd/core/no-animation';

@Component({
  standalone: true,
  selector: 'app-allergies-add-edit',
  templateUrl: './allergies-add-edit.component.html',
  styleUrls: ['./allergies-add-edit.component.css'],
  imports: [
    NzModalModule,
    TranslateModule,
    FormsModule,
    FlexLayoutModule,
    ReactiveFormsModule,
    NzSelectModule,
    CommonModule,
    NzButtonModule,
    NzTableModule,
    NzLayoutModule,
    TooltipComponent,
    NzIconModule,
    NzNoAnimationModule,
  ],
})
export class AllergiesAddEditComponent {
  private destroy$ = new Subject<void>();

  reactionsAddedDetailed: IReactionDetailed[] = [];

  allergyDropdown: IAllergyDropdown | undefined;

  isVisible = false;
  isLoading = false;

  isEdit = false;
  editAllergyId?: number;

  reactionsSubmitted = false;
  allergySubmitted = false;

  @ViewChild('modalRef', { static: true }) modalRef!: NzModalRef; // Reference to the modal

  allergyForm: FormGroup = new FormGroup({});

  reactionsForm: FormGroup = new FormGroup({});

  constructor(
    private readonly allergyService: AllergyService,
    private readonly formBuilder: FormBuilder,
    private translate: TranslateService
  ) {}

  ngOnInit() {
    this.isLoading = true;
    this.isEdit = false;

    this.allergyService.getAllergyDropdown().subscribe({
      next: (pro) => {
        this.allergyDropdown = pro?.data;

        this.isLoading = false;
      },
      error: (err) => {
        console.log(err);
      },
    });

    this.allergyForm = this.formBuilder.group({
      typeValue: [null, Validators.required],
      criticalityValue: [null, Validators.required],
      certaintyValue: [null, Validators.required],
      statusValue: [null, Validators.required],
      onsetDate: [null, Validators.required],
      endDate: [null],
      description: [null, Validators.required],
    });

    this.reactionsForm = this.formBuilder.group({
      manifestationValue: [null, Validators.required],
      description: [null, Validators.required],
      severityValue: [null, Validators.required],
    });

    this.allergyService.allergyEditSub
      .pipe(takeUntil(this.destroy$))
      .subscribe((data) => {
        // GET DATA FIELDS AND ASSIGN DEFAULT VALUES TO FORM
        if (data) {
          this.isEdit = true;
          this.editAllergyId = data?.id;

          this.allergyForm.patchValue({
            typeValue: data?.allergyOrIntoleranceTypeId,
            criticalityValue: data?.criticalityId,
            certaintyValue: data?.certaintyId,
            statusValue: data?.clinicalStatusId,
            onsetDate: data?.onsetDate,
            endDate: data?.endDate,
            description: data?.description,
          });

          this.reactionsAddedDetailed = [...data?.reactionDto!];
        } else {
          this.isEdit = false;
          this.editAllergyId = undefined;

          this.reactionsAddedDetailed = [];

          this?.allergyForm.reset();
          this?.reactionsForm.reset();
        }
      });

    this.allergyService.allergyModalSub
      .pipe(takeUntil(this.destroy$))
      .subscribe((showModal) => {
        this.isVisible = showModal;
      });
  }

  ngOnDestroy() {
    // Unsubscribe from observables
    this.destroy$.next();
    this.destroy$.complete();

    this?.modalRef.destroy();
  }

  public allergyName?: string;

  getCurrentDate() {
    return new Date().toISOString().split('T')[0];
  }

  handleCancel() {
    this.reactionsAddedDetailed = [];

    this.allergyForm.reset();
    this.reactionsForm.reset();

    this.allergySubmitted = false;
    this.reactionsSubmitted = false;

    this.isLoading = false;

    this.allergyService.allergyModalSub.next(false);

    this.modalRef.destroy();
  }

  submitForm() {
    this.isLoading = true;
    this.allergySubmitted = true;

    if (this.allergyForm.valid) {
      const allergyObj: IAddAllergy = {};

      allergyObj.id = this.editAllergyId;
      allergyObj.allergyOrIntoleranceTypeId = this.allergyForm.value?.typeValue;
      allergyObj.criticalityId = this.allergyForm.value?.criticalityValue;
      allergyObj.certaintyId = this.allergyForm.value?.certaintyValue;
      allergyObj.clinicalStatusId = this.allergyForm.value?.statusValue;
      allergyObj.onsetDate = this.allergyForm.value?.onsetDate;
      allergyObj.endDate = this.allergyForm.value?.endDate;
      allergyObj.description = this.allergyForm.value?.description;
      allergyObj.reactionDto = this.reactionsAddedDetailed;

      if (this.isEdit) {
        this.allergyService.editAllergy(allergyObj).subscribe({
          next: () => {
            Swal.fire({
              text: 'Allergy edited successfully!',
              toast: true,
              position: 'bottom-end',
              showCancelButton: false,
              showConfirmButton: false,
              color: 'white',
              background: '#0d9488',
              timer: 3000,
            });

            this.allergyService.getData();

            this.handleCancel();
          },
          error: (err) => {
            Swal.fire({
              text: 'Unable to edit allergy!',
              toast: true,
              position: 'bottom-end',
              showCancelButton: false,
              showConfirmButton: false,
              color: 'white',
              background: '#ff6969',
              timer: 3000,
            });
            console.log(err);

            this.handleCancel();
          },
        });
      } else {
        this.allergyService.addAllergy(allergyObj).subscribe({
          next: () => {
            Swal.fire({
              text: 'Allergy added successfully!',
              toast: true,
              position: 'bottom-end',
              showCancelButton: false,
              showConfirmButton: false,
              color: 'white',
              background: '#0d9488',
              timer: 3000,
            });

            this.allergyService.getData();

            this.handleCancel();
          },
          error: (err) => {
            Swal.fire({
              text: 'Unable to add allergy!',
              toast: true,
              position: 'bottom-end',
              showCancelButton: false,
              showConfirmButton: false,
              color: 'white',
              background: '#ff6969',
              timer: 3000,
            });
            console.log(err);

            this.handleCancel();
          },
        });
      }
    } else {
      this.isLoading = false;
    }
  }

  addReaction() {
    this.reactionsSubmitted = true;

    if (this.reactionsForm.valid) {
      const reactionObj: IReactionDetailed = {};

      reactionObj.reactionId = uuidv4();

      reactionObj.manifestationId =
        this.reactionsForm.value?.manifestationValue;
      reactionObj.severityId = this.reactionsForm.value?.severityValue;
      reactionObj.description = this.reactionsForm.value?.description;
      reactionObj.manifestation = this.allergyDropdown?.manifestation.find(
        (m) =>
          m.id.toString() ==
          this.reactionsForm.value?.manifestationValue.toString()
      )?.name;
      reactionObj.severity = this.allergyDropdown?.severity.find(
        (s) =>
          s.id.toString() == this.reactionsForm.value?.severityValue.toString()
      )?.name;

      this.reactionsAddedDetailed.push(reactionObj);

      this.reactionsForm.reset();

      this.reactionsSubmitted = false;
    }
  }

  removeReaction(reactionId: string) {
    this.reactionsAddedDetailed = this.reactionsAddedDetailed.filter(
      (r) => r.reactionId != reactionId && r.id?.toString() != reactionId
    );
  }
}
