import { Component, Input } from '@angular/core';
import { IModule } from 'src/app/interfaces/IModule';
import { paths } from 'src/component-paths/component-paths';

@Component({
  selector: 'app-daily-monitoring-section',
  templateUrl: './daily-monitoring-section.component.html',
  styleUrls: ['./daily-monitoring-section.component.css'],
})
export class DailyMonitoringSectionComponent {
  @Input() submenu?: IModule[];

  componentPaths = paths;
}
