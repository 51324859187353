import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import {
  IGenericServiceResponse,
  IServiceResponse,
} from '../interfaces/IServiceResponse';
import { environment } from 'src/environments/environment';
import { EpisodeOfCareService } from './episodeOfCare.service';
import { PatientService } from './patient.service';
import Swal from 'sweetalert2';
import { IAddImaging } from '../interfaces/Imaging/IAddImaging';
import { IImagingDropdown } from '../interfaces/Imaging/IImagingDropdown';
import { IImagingDetailed } from '../interfaces/Imaging/IImagingDetailed';
import { saveAs } from 'file-saver-es';

@Injectable()
export class ImagingService {
  public imagingModalSub = new BehaviorSubject<boolean>(false);

  public imagingEditSub = new BehaviorSubject<IImagingDetailed | undefined>(
    undefined
  );

  public imagingDataSub = new BehaviorSubject<IImagingDetailed[]>([]);

  public imagingLoadingSub = new BehaviorSubject<boolean>(false);

  constructor(
    private readonly _http: HttpClient,
    private readonly eocService: EpisodeOfCareService,
    private readonly patientService: PatientService
  ) {}

  async getData() {
    this.imagingLoadingSub.next(true);
    await this.getImaging(
      this.patientService.patientIDsignal(),
      this.eocService.currentVisitId()
    ).subscribe({
      next: (response) => {
        this.imagingDataSub.next(response?.data!);
        this.imagingLoadingSub.next(false);
      },
      error: (err) => {
        Swal.fire({
          text: 'Unable to retrieve Imaging!',
          toast: true,
          position: 'bottom-end',
          showCancelButton: false,
          showConfirmButton: false,
          color: 'white',
          background: '#ff6969',
          timer: 3000,
        });
        console.error('Error fetching procedures:', err);
      },
    });
  }

  getImaging(
    patientId: string,
    visitId?: string
  ): Observable<IGenericServiceResponse<IImagingDetailed[]>> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    if (visitId) {
      return this._http.get<IGenericServiceResponse<IImagingDetailed[]>>(
        `${environment.BACKEND_URL}Imaging/${patientId}?visitId=${visitId}`,
        {
          headers: headers,
        }
      );
    } else {
      return this._http.get<IGenericServiceResponse<IImagingDetailed[]>>(
        `${environment.BACKEND_URL}Imaging/${patientId}`,
        {
          headers: headers,
        }
      );
    }
  }

  getPDF(imageId: string): Observable<Blob> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    return this._http.get(
      `${environment.BACKEND_URL}Imaging/DownloadPDF/${imageId}`,
      {
        headers: headers,
        responseType: 'blob',
      }
    );
  }

  downloadPDF(item: IImagingDetailed): void {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });

    this._http
      .get(
        `${
          environment.BACKEND_URL
        }Imaging/DownloadPDF/${item?.id?.toString()!}`,
        {
          headers: headers,
          responseType: 'blob',
        }
      )
      .subscribe(
        (blob) => {
          const file = new Blob([blob], { type: 'application/pdf' });
          saveAs(file, item.examTitle + '.pdf');
        },
        (error) => {
          Swal.fire({
            text: 'Unable to download the document!',
            toast: true,
            position: 'bottom-end',
            showCancelButton: false,
            showConfirmButton: false,
            color: 'white',
            background: '#ff6969',
            timer: 3000,
          });
          console.error('Error downloading PDF:', error);
        }
      );
  }

  getImagingDropdown(): Observable<IGenericServiceResponse<IImagingDropdown>> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    return this._http.get<IGenericServiceResponse<IImagingDropdown>>(
      `${environment.BACKEND_URL}Imaging/DropdownData`,
      {
        headers: headers,
      }
    );
  }

  getDropdown(): Observable<IGenericServiceResponse<IImagingDropdown>> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    return this._http.get<IGenericServiceResponse<IImagingDropdown>>(
      `${environment.BACKEND_URL}Imaging/DropdownData/RareDisease`,
      {
        headers: headers,
      }
    );
  }

  addImaging(imagingObj: IAddImaging) {
    imagingObj.visitId = this.eocService.currentVisitId();
    imagingObj.patientId = this.patientService.patientIDsignal();

    console.log(imagingObj);

    const formData = new FormData();
    formData.append('CategoryId', imagingObj?.categoryId?.toString()!);
    formData.append('ExamTitle', imagingObj?.examTitle!);
    formData.append('Date', imagingObj?.date!);
    formData.append('Description', imagingObj?.description!);
    formData.append('file', imagingObj?.file!);

    formData.append('PatientId', imagingObj?.patientId?.toString()!);
    formData.append('VisitId', imagingObj?.visitId?.toString()!);

    // const headers = new HttpHeaders({
    //   'Content-Type': 'multipart/form-data; boundary=----',
    // });

    return this._http.post(`${environment.BACKEND_URL}Imaging`, formData);
  }

  editImaging(imagingObj: IAddImaging) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });

    return this._http.put(
      `${environment.BACKEND_URL}Imaging/${imagingObj.id}`,
      imagingObj,
      {
        headers: headers,
      }
    );
  }

  deleteImaging(imagingId: string): Observable<IServiceResponse> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });

    return this._http.delete<IServiceResponse>(
      `${environment.BACKEND_URL}Imaging/${imagingId}`,
      {
        headers: headers,
      }
    );
  }

  deleteImagingFromTable(imagingId: string) {
    // REMOVE THE Imaging
    this.imagingDataSub.next(
      this.imagingDataSub.value?.filter((rp) => rp.id?.toString() != imagingId)
    );
  }
}
