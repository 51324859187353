import { DatePipe } from '@angular/common';
import { Component, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { NavigationExtras, Router } from '@angular/router';
import { KeycloakService } from 'keycloak-angular';
import { NzModalRef } from 'ng-zorro-antd/modal';
import { NzSelectOptionInterface } from 'ng-zorro-antd/select';
import { IAddDischarge } from 'src/app/interfaces/Discharge/IAddDischarge';
import { IDischargeDropdown } from 'src/app/interfaces/Discharge/IDischargeDropdown';
import {
  IAccommodationDropdown,
  IAccomodationPatient,
} from 'src/app/interfaces/Dropdown/IAccommodationDropdown';
import { ICoding } from 'src/app/interfaces/ICoding';
import { IInpatient } from 'src/app/interfaces/IInpatient';
import {
  ardsRV,
  glasgowScoreRV,
  mewsRV,
} from 'src/app/interfaces/IReferenceValue';
import { ISearchInpatient } from 'src/app/interfaces/ISearchInpatient';
import { AllergyService } from 'src/app/services/allergy.service';
import { CarePlanService } from 'src/app/services/carePlan.service';
import { CurrentMedicationService } from 'src/app/services/currentMedication.service';
import { CurrentProblemsService } from 'src/app/services/currentProblems.service';
import { DischargeService } from 'src/app/services/discharge.service';
import { EpisodeOfCareService } from 'src/app/services/episodeOfCare.service';
import { ImagingService } from 'src/app/services/imaging.service';
import { InpatientService } from 'src/app/services/inpatient.service';
import { LaboratoryFileService } from 'src/app/services/laboratoryFile.service';
import { MedicalAlertService } from 'src/app/services/medicalAlert.service';
import { MedicalDeviceService } from 'src/app/services/medicalDevice.service';
import { MedicalHistoryService } from 'src/app/services/medicalHistory.service';
import { PastMedicationService } from 'src/app/services/pastMedication.service';
import { PatientService } from 'src/app/services/patient.service';
import { PregnancyHistoryService } from 'src/app/services/pregnancyHistory.service';
import { PregnancyOutcomeService } from 'src/app/services/pregnancyOutcome.service';
import { PregnancyStatusService } from 'src/app/services/pregnancyStatus.service';
import { ProcedureService } from 'src/app/services/procedure.service';
import { ResolvedProblemsService } from 'src/app/services/resolvedProblems.service';
import { SocialHistoryService } from 'src/app/services/socialHistory.service';
import { TravelHistoryService } from 'src/app/services/travelHistory.service';
import { VaccinationService } from 'src/app/services/vaccination.service';
import { VitalSignService } from 'src/app/services/vitalSign.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-in-patients',
  templateUrl: './in-patients.component.html',
  styleUrls: ['./in-patients.component.css'],
  providers: [DatePipe],
})
export class InPatientsComponent {
  @ViewChild('inpaginator', { static: true }) paginator!: MatPaginator;

  @ViewChild('bedModalRef', { static: true }) bedModalRef!: NzModalRef; // Reference to the modal
  @ViewChild('dischargeModalRef', { static: true })
  dischargeModalRef!: NzModalRef; // Reference to the modal

  searchForm: FormGroup;

  bedForm: FormGroup = new FormGroup({});
  dischargeForm: FormGroup = new FormGroup({});

  bedSubmitted = false;
  dischargeSubmitted = false;

  situationDisabled = false;

  isLoading = false;

  isAssignLoading = false;
  isDischargeLoading = false;

  isAssignVisible = false;
  isDischargeVisible = false;

  public availablePatients: NzSelectOptionInterface[] = [];
  public reasons: ICoding[] = [];

  public data: MatTableDataSource<IInpatient> =
    new MatTableDataSource<IInpatient>([]);
  private filteredData: any[] = [];
  private tableData: any[] = [];

  public totalRecords: number = 0;

  public metricsData: any[] = [];

  private userRoles: string[] = [];
  public dischargeVisible?: boolean;

  dischargeDropdown?: IDischargeDropdown;

  finalDiagnosis: NzSelectOptionInterface[] = [];

  dropdownData: IAccommodationDropdown = {
    buildings: [],
    floors: [],
    wards: [],
    rooms: [],
  };

  public displayedColumns: string[] = [
    'location',
    'bedNumber',
    'patientFullName',
    'patientAge',
    'lengthOfStay',
    'sepsis',
    'saps2',
    'apache2',
    'apache4',
    'sofa',
    'ards',
    'mews',
    'glasgow',
    // 'admissionDate',
    'inpatient-actions',
  ];

  public ardsRV = ardsRV;
  public mewsRV = mewsRV;
  public glasgowScoreRV = glasgowScoreRV;

  ngAfterViewInit() {
    this.data.paginator = this.paginator;
  }

  constructor(
    private readonly inpatientService: InpatientService,
    private readonly keycloakService: KeycloakService,
    private readonly eocService: EpisodeOfCareService,
    private readonly patientService: PatientService,
    private readonly router: Router,
    private readonly fb: FormBuilder,
    private readonly allergyService: AllergyService,
    private readonly vaccinationService: VaccinationService,
    private readonly medicalAlertService: MedicalAlertService,
    private readonly medicalHistoryService: MedicalHistoryService,
    private readonly procedureService: ProcedureService,
    private readonly medicalDeviceService: MedicalDeviceService,
    private readonly currentProblemsService: CurrentProblemsService,
    private readonly resolvedProblemsService: ResolvedProblemsService,
    private readonly pregnancyHistoryService: PregnancyHistoryService,
    private readonly pregnancyOutcomeService: PregnancyOutcomeService,
    private readonly pregnancyStatusService: PregnancyStatusService,
    private readonly carePlanService: CarePlanService,
    private readonly socialHistoryService: SocialHistoryService,
    private readonly travelHistoryService: TravelHistoryService,
    private readonly currentMedicationService: CurrentMedicationService,
    private readonly pastMedicationService: PastMedicationService,
    private readonly imagingService: ImagingService,
    private readonly labService: LaboratoryFileService,
    private readonly vitalSignService: VitalSignService,
    private readonly dischargeService: DischargeService
  ) {
    this.searchForm = this.fb.group({
      buildingValue: [''],
      floorValue: [''],
      wardValue: [''],
      roomValue: [''],
    });

    this.bedForm = this.fb.group({
      patientValue: ['', Validators.required],
      bedValue: ['', Validators.required],
      methodValue: ['', Validators.required],
      noteValue: [''],
    });

    this.dischargeForm = this.fb.group({
      diagnosisValue: ['', Validators.required],
      dischargeTypeValue: ['', Validators.required],
      dischargeSituationValue: ['', Validators.required],
      dischargeTypeNotesValue: [''],
      dischargeSituationNotesValue: [''],
      eocValue: ['', Validators.required],
    });
  }

  getRow(element: any) {
    return (
      this.metricsData?.find((d: any) => d?.id === element?.id) || undefined
    );
  }

  dropdownChanged() {
    this.paginator.pageIndex = 0;
    this.searchPatientsForm();
  }

  async searchPatientsForm() {
    const searchParams: ISearchInpatient = {};

    if (this.searchForm.get('buildingValue')?.value) {
      searchParams.buildingId = this.searchForm.get('buildingValue')?.value;
    }

    if (this.searchForm.get('floorValue')?.value) {
      searchParams.floorId = this.searchForm.get('floorValue')?.value;
    }

    if (this.searchForm.get('wardValue')?.value) {
      searchParams.wardId = this.searchForm.get('wardValue')?.value;
    }

    if (this.searchForm.get('roomValue')?.value) {
      searchParams.roomId = this.searchForm.get('roomValue')?.value;
    }

    await this.inpatientService
      .getInpatients(
        searchParams,
        this.paginator.pageSize,
        this.paginator.pageIndex + 1
      )
      .subscribe({
        next: (d) => {
          this.data = new MatTableDataSource(
            d?.data?.map((d: IInpatient) => d)
          );

          this.tableData = d?.data?.map((d: IInpatient) => d);
          this.filteredData = d?.data?.map((d: IInpatient) => d);

          this.metricsData = this.filteredData.map((d) => {
            if (!d.bedIsAvailable) {
              return {
                id: d.id,
                sepsis: Math.floor(Math.random() * 5),
                saps2: Math.floor(Math.random() * (163 + 1)),
                apache2: Math.floor(Math.random() * (71 + 1)),
                apache4: Math.floor(Math.random() * (30 + 1)) + 256,
                sofa: Math.floor(Math.random() * (24 + 1)),
                ards: Math.floor(Math.random() * (300 + 1)),
                mews: Math.floor(Math.random() * (13 + 1)) + 2,
                glasgow: Math.floor(Math.random() * (12 + 1)) + 3,
              };
            } else {
              return undefined;
            }
          });

          this.totalRecords = d.totalRecords || 0;
        },
        error: (err) => {},
      });
  }

  getDropdownData() {
    this.inpatientService.getDropdownData().subscribe({
      next: (d) => {
        this.dropdownData = d.data;
      },
      error: (err) => {},
    });
  }

  ngOnInit() {
    this.keycloakService.getToken().then((token) => {
      if (token) {
        const payload = JSON.parse(atob(token.split('.')[1]));
        this.userRoles = payload['resource_access']['ehr-ui']['roles'];

        this.userRoles?.includes('Nurse')
          ? (this.dischargeVisible = false)
          : (this.dischargeVisible = true);
      }
    });

    this.searchForm.reset();

    this.searchPatientsForm();
    this.getDropdownData();
  }

  handleAssignCancel() {
    this.isAssignVisible = false;

    this.bedForm.reset();

    this.bedSubmitted = false;

    this.isLoading = false;

    this.bedModalRef.destroy();
  }

  handleDischargeCancel() {
    this.isDischargeVisible = false;

    this.dischargeForm.reset();

    this.dischargeSubmitted = false;

    this.isLoading = false;

    this.dischargeModalRef.destroy();
  }

  clearDataFromServices() {
    this.allergyService.allergyDataSub.next({});
    this.vaccinationService.vaccinationDataSub.next({});
    this.medicalAlertService.medicalAlertDataSub.next([]);
    this.medicalHistoryService.medicalHistoryDataSub.next([]);
    this.procedureService.procedureDataSub.next({});
    this.medicalDeviceService.medicalDeviceDataSub.next({});
    this.currentProblemsService.currentProblemDataSub.next({});
    this.resolvedProblemsService.resolvedProblemDataSub.next([]);
    this.pregnancyHistoryService.pregnancyHistoryDataSub.next([]);
    this.pregnancyOutcomeService.pregnancyOutcomeDataSub.next([]);
    this.pregnancyStatusService.pregnancyStatusDataSub.next([]);
    this.carePlanService.carePlanDataSub.next([]);
    this.socialHistoryService.socialHistoryDataSub.next([]);
    this.travelHistoryService.travelHistoryDataSub.next([]);
    this.currentMedicationService.currentMedicationDataSub.next({});
    this.pastMedicationService.pastMedicationDataSub.next([]);
    this.imagingService.imagingDataSub.next([]);
    this.labService.laboratoryDataSub.next([]);
    this.vitalSignService.vitalSignDataSub.next([]);
  }

  async submitDischargeForm() {
    this.isDischargeLoading = true;
    this.dischargeSubmitted = true;

    if (this.dischargeForm.valid) {
      let dischargeObj: IAddDischarge = {};
      dischargeObj.episodeCareId = this.dischargeForm.get('eocValue')?.value;
      dischargeObj.finalDiagnosisId =
        this.dischargeForm.get('diagnosisValue')?.value;
      dischargeObj.dischargeTypeId =
        this.dischargeForm.get('dischargeTypeValue')?.value;
      dischargeObj.dischargeTypeNotes =
        this.dischargeForm.get('dischargeTypeNotesValue')?.value || undefined;
      dischargeObj.dischargeSituationId = this.dischargeForm.get(
        'dischargeSituationValue'
      )?.value;
      dischargeObj.dischargeSituationNotes =
        this.dischargeForm.get('dischargeSituationNotesValue')?.value ||
        undefined;

      this.dischargeService.addDischarge(dischargeObj).subscribe({
        next: async (res) => {
          this.isDischargeLoading = false;

          // UPDATE TABLE
          await this.searchPatientsForm().then(() => {
            this.isDischargeVisible = false;
          });

          this.dischargeForm.setErrors(null);
          this.bedForm.setErrors(null);

          this.dischargeModalRef.destroy();

          this.dischargeSubmitted = false;

          Swal.fire({
            text: 'Patient discharged successfully!',
            toast: true,
            position: 'bottom-end',
            showCancelButton: false,
            showConfirmButton: false,
            color: 'white',
            background: '#0d9488',
            timer: 3000,
          });
        },
        error: (err) => {
          this.isDischargeLoading = false;

          Swal.fire({
            text: 'Unable to discharge patient!',
            toast: true,
            position: 'bottom-end',
            showCancelButton: false,
            showConfirmButton: false,
            color: 'white',
            background: '#ff6969',
            timer: 3000,
          });
          console.log('error: ', err);
        },
      });
    } else {
      this.isDischargeLoading = false;
    }
  }

  async submitBedForm() {
    this.isAssignLoading = true;
    this.bedSubmitted = true;

    if (this.bedForm.valid) {
      this.eocService
        .createInpatient(
          this.bedForm.get('patientValue')?.value || undefined,
          this.bedForm.get('bedValue')?.value || undefined,
          this.bedForm.get('methodValue')?.value || undefined,
          this.bedForm.get('noteValue')?.value || undefined
        )
        .subscribe({
          next: async (res) => {
            // SET EOC AND VISIT IDs
            res?.data?.episodeOfCareId?.toString() &&
              this.eocService.currentEocId.set(
                res?.data?.episodeOfCareId?.toString()
              );

            res?.data?.visitId?.toString() &&
              this.eocService.currentVisitId.set(
                res?.data?.visitId?.toString()
              );

            // SET PATIENT ID
            this.patientService.patientIDsignal.set(
              this.bedForm.get('patientValue')?.value
            );

            this.isAssignLoading = false;

            // UPDATE TABLE
            await this.searchPatientsForm().then(() => {
              this.isAssignVisible = false;
            });

            this.dischargeForm.setErrors(null);
            this.bedForm.setErrors(null);

            this.bedModalRef.destroy();

            this.bedSubmitted = false;

            // RESET DATA  BEFORE ACCESSING A PATIENT
            this.clearDataFromServices();

            // WAIT 100MS FOR THE HTML MODAL TO BE UPDATED AND CLOSED
            // AND THEN PROCEED WITH THE REDIRECTION
            setTimeout(() => {
              const navigationExtras: NavigationExtras = {
                queryParams: {
                  patientID: this.bedForm.get('patientValue')?.value,
                },
              };

              this.router.navigate(['/ehr/inPatientView'], navigationExtras);
            }, 100);

            Swal.fire({
              text: 'Patient assigned to bed successfully!',
              toast: true,
              position: 'bottom-end',
              showCancelButton: false,
              showConfirmButton: false,
              color: 'white',
              background: '#0d9488',
              timer: 3000,
            });
          },
          error: (err) => {
            this.isAssignLoading = false;

            Swal.fire({
              text: 'Unable to assign patient to bed!',
              toast: true,
              position: 'bottom-end',
              showCancelButton: false,
              showConfirmButton: false,
              color: 'white',
              background: '#ff6969',
              timer: 3000,
            });
            console.log('error: ', err);
          },
        });
    } else {
      this.isAssignLoading = false;
    }
  }

  handleTypeChange(event: any) {
    if (event == 4) {
      this.dischargeForm.get('dischargeSituationValue')?.setValue(5);
      this.situationDisabled = true;
    } else {
      this.dischargeForm.get('dischargeSituationValue')?.setValue(undefined);
      this.situationDisabled = false;
    }
  }

  handleDischarge(item: IInpatient) {
    this.dischargeForm.reset();

    this.dischargeForm
      .get('eocValue')
      ?.setValue(item?.inPatientInfo?.episodeOfCareId);

    this.isDischargeVisible = true;
    this.isDischargeLoading = true;

    this.dischargeService
      .getDischargeDropdown(item?.inPatientInfo?.episodeOfCareId?.toString())
      .subscribe({
        next: (res) => {
          this.dischargeDropdown = res.data;

          this.finalDiagnosis = res.data.finalDiagnostic.map((d) => ({
            label: d?.name,
            value: d?.id,
          }));

          this.dischargeForm
            .get('diagnosisValue')
            ?.setValue(res.data.potentialDiagnosis.id);

          this.isDischargeLoading = false;

          this.dropdownChanged();
        },
        error: (err) => {
          Swal.fire({
            text: 'Unable to retrieve discharge options!',
            toast: true,
            position: 'bottom-end',
            showCancelButton: false,
            showConfirmButton: false,
            color: 'white',
            background: '#ff6969',
            timer: 3000,
          });
        },
      });
  }

  handleAdd(item: IInpatient) {
    this.bedForm.reset();
    if (item.id) {
      this.bedForm.get('bedValue')?.setValue(item?.id);
      this.isAssignVisible = true;
      this.isAssignLoading = true;
      this.inpatientService.getAvailablePatients().subscribe({
        next: (d) => {
          this.availablePatients = d.data.availablePatients.map((d) => {
            return {
              label: d.fullName,
              value: d.id,
            };
          });

          this.reasons = d.data.admissionReasons;
          this.isAssignLoading = false;
        },
        error: (err) => {
          Swal.fire({
            text: 'Unable to retrieve available Patients!',
            toast: true,
            position: 'bottom-end',
            showCancelButton: false,
            showConfirmButton: false,
            color: 'white',
            background: '#ff6969',
            timer: 3000,
          });

          console.log(err);
        },
      });
    }
  }

  handleView(item: IInpatient) {
    this.isLoading = true;
    if (item?.inPatientInfo?.episodeOfCareId) {
      // REQUEST NEW VISIT AND ASSIGN VISIT ID TO EOC SERVICE THEN NAVIGATE
      // this.eocService.currentVisitId.set(res?.data?.visitId?.toString());
      // this.router.navigate(['/inPatientView']);
      Swal.fire({
        text: `Are you sure that you want to create a new Visit for ${item?.inPatientInfo?.patientFullName}?`,
        showDenyButton: false,
        showCancelButton: true,
        confirmButtonText: 'Ok',
      }).then((result) => {
        if (result.isConfirmed) {
          this.eocService
            .createNewVisit(item.inPatientInfo.episodeOfCareId)
            .subscribe({
              next: (res) => {
                res?.data?.episodeOfCareId?.toString() &&
                  this.eocService.currentEocId.set(
                    res?.data?.episodeOfCareId?.toString()
                  );

                res?.data?.visitId?.toString() &&
                  this.eocService.currentVisitId.set(
                    res?.data?.visitId?.toString()
                  );

                this.isLoading = false;

                this.patientService.patientIDsignal.set(
                  item.inPatientInfo?.patientId?.toString()
                );

                // RESET DATA  BEFORE ACCESSING A PATIENT
                this.clearDataFromServices();

                const navigationExtras: NavigationExtras = {
                  queryParams: {
                    patientID: this.patientService.patientIDsignal(),
                  },
                };

                this.router.navigate(['/ehr/inPatientView'], navigationExtras);

                Swal.fire({
                  text: 'New Visit created successfully!',
                  toast: true,
                  position: 'bottom-end',
                  showCancelButton: false,
                  showConfirmButton: false,
                  color: 'white',
                  background: '#0d9488',
                  timer: 3000,
                });
              },
              error: (err) => {
                Swal.fire({
                  text: 'Unable to create New Visit!',
                  toast: true,
                  position: 'bottom-end',
                  showCancelButton: false,
                  showConfirmButton: false,
                  color: 'white',
                  background: '#ff6969',
                  timer: 3000,
                });

                console.log('error');
              },
            });
        } else {
          this.isLoading = false;
        }
      });
    }
  }
}
