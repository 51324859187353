// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* detailed-patient.component.css */
.patient-details-container {
  padding-top: 30px;
  padding-left: 50px;
  padding-bottom: 30px;
  margin: auto;
  height: auto;
}

.data-container{

}
.form-row{
  display: flex;
  justify-content: space-between;
}

.titleBar {
  display: flex;
  align-items: center;
}
.titleBar::before {
  content: "";
  width: 5px;
  height: 30px;
  background-color: #0d9488;
  margin-right: 10px;
}

.infoBar {
  display: flex;
  align-items: center;
  margin-left: -15px;
}
.infoBar::before {
  content: "";
  width: 5px;
  height: 30px;
  background-color: #53c9c1;
  margin-right: 10px;
}

.personal-info, .address-info, .documentation-info {
  padding-top: 20px;
  padding-bottom: 20px;
  padding-left: 20px;
}

.info-item {
  min-width: 350px;
  margin-bottom: 10px;
}

label {
  font-weight: bold;
  margin-right: 8px;
}

span {
  color: #333;
}
`, "",{"version":3,"sources":["webpack://./src/app/components/ehr/patients/detailed-profile/detailed-profile.component.css"],"names":[],"mappings":"AAAA,mCAAmC;AACnC;EACE,iBAAiB;EACjB,kBAAkB;EAClB,oBAAoB;EACpB,YAAY;EACZ,YAAY;AACd;;AAEA;;AAEA;AACA;EACE,aAAa;EACb,8BAA8B;AAChC;;AAEA;EACE,aAAa;EACb,mBAAmB;AACrB;AACA;EACE,WAAW;EACX,UAAU;EACV,YAAY;EACZ,yBAAyB;EACzB,kBAAkB;AACpB;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,kBAAkB;AACpB;AACA;EACE,WAAW;EACX,UAAU;EACV,YAAY;EACZ,yBAAyB;EACzB,kBAAkB;AACpB;;AAEA;EACE,iBAAiB;EACjB,oBAAoB;EACpB,kBAAkB;AACpB;;AAEA;EACE,gBAAgB;EAChB,mBAAmB;AACrB;;AAEA;EACE,iBAAiB;EACjB,iBAAiB;AACnB;;AAEA;EACE,WAAW;AACb","sourcesContent":["/* detailed-patient.component.css */\r\n.patient-details-container {\r\n  padding-top: 30px;\r\n  padding-left: 50px;\r\n  padding-bottom: 30px;\r\n  margin: auto;\r\n  height: auto;\r\n}\r\n\r\n.data-container{\r\n\r\n}\r\n.form-row{\r\n  display: flex;\r\n  justify-content: space-between;\r\n}\r\n\r\n.titleBar {\r\n  display: flex;\r\n  align-items: center;\r\n}\r\n.titleBar::before {\r\n  content: \"\";\r\n  width: 5px;\r\n  height: 30px;\r\n  background-color: #0d9488;\r\n  margin-right: 10px;\r\n}\r\n\r\n.infoBar {\r\n  display: flex;\r\n  align-items: center;\r\n  margin-left: -15px;\r\n}\r\n.infoBar::before {\r\n  content: \"\";\r\n  width: 5px;\r\n  height: 30px;\r\n  background-color: #53c9c1;\r\n  margin-right: 10px;\r\n}\r\n\r\n.personal-info, .address-info, .documentation-info {\r\n  padding-top: 20px;\r\n  padding-bottom: 20px;\r\n  padding-left: 20px;\r\n}\r\n\r\n.info-item {\r\n  min-width: 350px;\r\n  margin-bottom: 10px;\r\n}\r\n\r\nlabel {\r\n  font-weight: bold;\r\n  margin-right: 8px;\r\n}\r\n\r\nspan {\r\n  color: #333;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
