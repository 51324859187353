import { Component, Input, SimpleChanges } from '@angular/core';
import Chart from 'chart.js/auto';
import * as moment from 'moment';
import { IVitalSignDetailed } from 'src/app/interfaces/VitalSign/IVitalSignDetailed';

@Component({
  selector: 'app-vital-signs-chart',
  templateUrl: './vital-signs-chart.component.html',
  styleUrls: ['./vital-signs-chart.component.css'],
})
export class VitalSignsChartComponent {
  @Input() data?: IVitalSignDetailed[];

  chart: any;

  constructor() {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes['data'] && changes['data'].currentValue) {
      this.data = this.data?.sort((a, b) => {
        const dateA = a?.onSetDateTime
          ? new Date(a?.onSetDateTime)
          : new Date();
        const dateB = b?.onSetDateTime
          ? new Date(b?.onSetDateTime)
          : new Date();
        return dateA.getTime() - dateB.getTime();
      });

      this.updateChart();
    }
  }

  ngOnInit() {
    this.data = this.data?.sort((a, b) => {
      const dateA = a?.onSetDateTime ? new Date(a?.onSetDateTime) : new Date();
      const dateB = b?.onSetDateTime ? new Date(b?.onSetDateTime) : new Date();
      return dateA.getTime() - dateB.getTime();
    });
  }

  ngAfterViewInit() {
    this.updateChart();
  }

  updateChart() {
    if (this.chart) {
      this.chart.destroy();
    }

    if (this.data) {
      const labels: string[] = this.data?.map(
        (sign) =>
          (sign?.onSetDateTime &&
            moment(sign?.onSetDateTime).format('YYYY-MM-DD h:mm a')) ||
          ''
      );
      const temperatureData: (number | undefined)[] = this.data.map(
        (sign) => sign.temperature
      );
      const respiratoryRateData: (number | undefined)[] = this.data.map(
        (sign) => sign.respiratoryRate
      );
      const heartRateData = this.data.map((sign) => sign.heartRate);
      const systolicData = this.data.map((sign) => sign.systolicBloodPressure);
      const diastolicData = this.data.map(
        (sign) => sign.diastolicBloodPressure
      );
      // const urineData = this.data.map((sign) => sign.urineOutput);
      // const spo2Data = this.data.map((sign) => sign.spO2);

      this.chart = new Chart('canvas', {
        type: 'line',
        data: {
          labels: labels,
          datasets: [
            {
              label: 'Temperature (°C)',
              data: temperatureData,
              borderColor: 'rgb(130, 202, 250)',
              backgroundColor: 'rgb(130, 202, 250)',
              tension: 0.1,
              yAxisID: 'y1',
            },
            {
              label: 'Respiratory Rate (bpm)',
              data: respiratoryRateData,
              borderColor: 'rgb(0, 0, 139)',
              backgroundColor: 'rgb(0, 0, 139)',
              tension: 0.1,
            },
            {
              label: 'Heart Rate (bpm)',
              data: heartRateData,
              borderColor: 'rgb(255, 165, 0)',
              backgroundColor: 'rgb(255, 165, 0)',
              tension: 0.1,
            },
            {
              label: 'Diastolic (mm[Hg])',
              data: diastolicData,
              borderColor: 'rgb(0, 128, 0)',
              backgroundColor: 'rgb(0, 128, 0)',
              type: 'bar',
              barThickness: 10,
            },
            {
              label: 'Systolic (mm[Hg])',
              data: systolicData,
              borderColor: 'rgb(255, 0, 0)',
              backgroundColor: 'rgb(255, 0, 0)',
              type: 'bar',
              barThickness: 10,
            },
            // {
            //   label: 'Urine Output',
            //   data: urineData,
            //   fill: 'rgb(200, 153, 156)',
            //   borderColor: 'rgb(200, 153, 156)',
            //   tension: 0.1,
            // },
            // {
            //   label: 'SpO2',
            //   data: spo2Data,
            //   fill: 'rgb(34, 45, 156)',
            //   borderColor: 'rgb(34, 45, 156)',
            //   tension: 0.1,
            // },
          ],
        },
        options: {
          responsive: true,
          scales: {
            x: {
              stacked: true,
              beginAtZero: true,
              ticks: {
                display: true,
              },
            },
            y: {
              beginAtZero: true,
              ticks: {
                display: true,
              },
            },
            y1: {
              type: 'linear',
              display: true,
              beginAtZero: true,
              position: 'right',
            },
          },
        },
      });
    }
  }
}
