import {Component, OnInit} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {IWardsWithBedCount} from "../../../../interfaces/Dashboard/Beds/IWardsWithBedCount";
import {IGenericServiceResponse} from "../../../../interfaces/IServiceResponse";
import {IPatientsDropdown} from "../../../../interfaces/IPatientsDropdown";
import {environment} from "../../../../../environments/environment";

@Component({
  selector: 'app-dashboard-bed-availability-by-ward',
  templateUrl: './dashboard-bed-availability-by-ward.component.html',
  styleUrls: ['./dashboard-bed-availability-by-ward.component.css']
})
export class DashboardBedAvailabilityByWardComponent implements OnInit {
  wardsData: IWardsWithBedCount = { wards: [] };

  constructor(private http: HttpClient) { }

  ngOnInit(): void {
    this.fetchData();
  }

  fetchData(): void {
    this.http.get<IGenericServiceResponse<IWardsWithBedCount>>(
      `${environment.BACKEND_URL}Dashboard/AvailableBedsByWard`,
    ).subscribe(data =>
    {
      this.wardsData = data.data;
    });
  }
}
