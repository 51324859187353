import { Component } from '@angular/core';
import { CtParametersAddEditComponent } from './ct-parameters-add-edit/ct-parameters-add-edit.component';
import { Subject, takeUntil } from 'rxjs';
import { ICtParametersDetailed } from 'src/app/interfaces/CtParameters/ICtParametersDetailed';
import { CtParametersService } from 'src/app/services/ctparameters.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-ct-parameters',
  templateUrl: './ct-parameters.component.html',
  styleUrls: ['./ct-parameters.component.css'],
})
export class CtParametersComponent {
  public addComp = CtParametersAddEditComponent;

  private destroy$ = new Subject<void>();

  ctParameters?: ICtParametersDetailed[];
  isLoading: boolean = false;

  constructor(private readonly ctParametersService: CtParametersService) {}

  openModalFun(item?: ICtParametersDetailed) {
    this.ctParametersService.ctParametersModalSub.next(true);
    this.ctParametersService.ctParametersEditSub.next(item);
  }

  deleteFun(id: number) {
    Swal.fire({
      text: 'Are you sure that you want to delete this 3D-CT Parameters record?',
      showDenyButton: false,
      showCancelButton: true,
      confirmButtonText: 'Ok',
    }).then((result) => {
      if (result.isConfirmed) {
        this.ctParametersService.deleteCtParameters(id.toString()).subscribe({
          next: (d) => {
            Swal.fire({
              text: '3D-CT Parameters record deleted successfully!',
              toast: true,
              position: 'bottom-end',
              showCancelButton: false,
              showConfirmButton: false,
              color: 'white',
              background: '#0d9488',
              timer: 3000,
            });

            this.ctParametersService.deleteCtParametersFromTable(id.toString());
          },
          error: (err) => {
            Swal.fire({
              text: 'Unable to delete 3D-CT Parameters record!',
              toast: true,
              position: 'bottom-end',
              showCancelButton: false,
              showConfirmButton: false,
              color: 'white',
              background: '#ff6969',
              timer: 3000,
            });
            console.log(err);
          },
        });
      } else if (result.isDenied) {
        Swal.fire('3D-CT Parameters record is not deleted', '', 'info');
      }
    });
  }
  ngOnInit() {
    this.ctParametersService.ctParametersLoadingSub
      .pipe(takeUntil(this.destroy$))
      .subscribe((data: boolean) => {
        this.isLoading = data;
      });

    this.ctParametersService.ctParametersDataSub
      .pipe(takeUntil(this.destroy$))
      .subscribe((data: ICtParametersDetailed[]) => {
        this.ctParameters = data;
      });

    this.ctParametersService.getData();
  }
}
