import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable, signal } from '@angular/core';
import { environment } from '../../environments/environment';
import {BehaviorSubject, catchError, Observable, tap} from 'rxjs';
import { IGenericServiceResponse } from '../interfaces/IServiceResponse';
import { IPatient } from '../interfaces/IPatient';
import { ISearchPatient } from '../interfaces/ISearchPatient';
import { IPatientDetailed } from '../interfaces/Detailed/IPatientDetailed';
import { IDefinition, IDefinitions } from '../interfaces/IDefinitions';
import {ILaboratoryFileDetailed} from "../interfaces/LaboratoryFile/ILaboratoryFileDetailed";
import {saveAs} from "file-saver-es";
import Swal from "sweetalert2";

@Injectable()
export class PatientService {
  public patientInfoSub = new BehaviorSubject<IPatientDetailed>({});

  public patientLoadingSub = new BehaviorSubject<boolean>(false);

  public patientIDsignal = signal('');

  public definitionsSignal = signal([] as IDefinition[]);

  constructor(private _http: HttpClient) {}

  getPatients(
    paramsObj: ISearchPatient,
    pageSize: number = 10,
    pageNumber: number = 1
  ): Observable<IGenericServiceResponse<IPatient[]>> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });

    return this._http.get<IGenericServiceResponse<IPatient[]>>(
      `${environment.BACKEND_URL}Patient/Search/${pageNumber}/${pageSize}`,
      {
        headers: headers,
        params: new HttpParams({ fromObject: paramsObj as any }),
      }
    );
  }

  getPatientByID(
    id: string
  ): Observable<IGenericServiceResponse<IPatientDetailed>> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });

    return this._http.get<IGenericServiceResponse<IPatientDetailed>>(
      `${environment.BACKEND_URL}Patient/${id}`,
      {
        headers: headers,
      }
    );
  }

  getDefinitions(): Observable<IGenericServiceResponse<IDefinitions>> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });

    return this._http.get<IGenericServiceResponse<IDefinitions>>(
      `${environment.BACKEND_URL}Definitions`,
      {
        headers: headers,
      }
    );
  }

  getProfilePicture(id: string): Observable<Blob> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });

    return this._http.get(
      `${environment.BACKEND_URL}Patient/DownloadProfilePicture/${id}`,
      {
        headers: headers,
        responseType: 'blob'
      }
    ).pipe(
      catchError((error) => {
        // // Handle error
        // Swal.fire({
        //   text: 'Unable to download this profile picture!',
        //   toast: true,
        //   position: 'bottom-end',
        //   showCancelButton: false,
        //   showConfirmButton: false,
        //   color: 'white',
        //   background: '#ff6969',
        //   timer: 3000
        // });
        console.error('Error downloading profile picture:', error);
        throw error; // rethrow error to propagate it to the subscriber
      })
    );
  }
}
