import { Component, Input } from '@angular/core';
import { ICDADetailed } from 'src/app/interfaces/CDA/ICDADetailed';
import { IModule } from 'src/app/interfaces/IModule';
import { CDAService } from 'src/app/services/cda.service';
import { EpisodeOfCareService } from 'src/app/services/episodeOfCare.service';
import { PatientService } from 'src/app/services/patient.service';
import { paths } from 'src/component-paths/component-paths';
import {IPatientDetailed} from "../../../../../interfaces/Detailed/IPatientDetailed";
import {Subject, takeUntil} from "rxjs";

@Component({
  selector: 'app-patient-summary',
  templateUrl: './patient-summary.component.html',
  styleUrls: ['./patient-summary.component.css'],
})
export class PatientSummaryComponent {
  @Input() submenu?: IModule[];

  isLoading: boolean = false;

  private destroy$ = new Subject<void>();
  patientInfo? : IPatientDetailed;

  componentPaths = paths;

  data?: ICDADetailed;

  constructor(
    private readonly eocService: EpisodeOfCareService,
    private readonly patientService: PatientService,
    private readonly cdaService: CDAService
  ) {}

  ngOnInit() {
    this.isLoading = true;
    this.patientService.patientInfoSub.pipe(takeUntil(this.destroy$)).subscribe(
      (patientInfo? : IPatientDetailed) => {
      this.patientInfo = patientInfo;
    });


    this.cdaService.getCDA(this.patientService.patientIDsignal()).subscribe({
      next: (res) => {
        this.data = res.data?.at(0);
        this.isLoading = false;
      },
      error: (err) => {
        console.log(err);
      },
    });
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
