import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { LocaleService } from 'src/app/services/locale.service';
import { IModule } from 'src/app/interfaces/IModule';
import { KeycloakService } from 'keycloak-angular';
import { NzI18nService, el_GR, en_US } from 'ng-zorro-antd/i18n';
import { TenantSettingsService } from 'src/app/services/tenantSettings.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css'],
})
export class HeaderComponent {
  @Input() activeButton: string = '';
  @Input() menuList: IModule[] | undefined;

  public initials: string = '';

  public logo: string = '';

  defaultLanguage = this.localeService.getCurrentLocale() || 'en';

  //language: string = 'en'; // Initial value

  constructor(
    private readonly router: Router,
    private readonly translate: TranslateService,
    private readonly localeService: LocaleService,
    private readonly keycloakService: KeycloakService,
    private readonly i18n: NzI18nService,
    private readonly tenantService: TenantSettingsService
  ) {}

  ngOnInit(): void {
    this.tenantService.downloadPicture(1).subscribe({
      next: (response) => {
        const reader = new FileReader();
        reader.readAsDataURL(response);
        reader.onload = () => {
          this.logo = reader.result as string;
        };
      },
      error: (error) => {
        console.error('Error downloading picture:', error);
      },
    });

    this.translate.use(this.defaultLanguage);
    this.keycloakService.getToken().then((token) => {
      if (token) {
        const payload = JSON.parse(atob(token.split('.')[1]));
        const first = payload['given_name'];
        const second = payload['family_name'];
        this.initials = (first[0] + second[0]).toUpperCase();
      }
    });
  }

  changeLanguage(selectedLanguage: string) {
    if (selectedLanguage) {
      if (selectedLanguage == 'English') {
        this.i18n.setLocale(en_US);
      } else {
        this.i18n.setLocale(el_GR);
      }
      this.localeService.updateLocale(selectedLanguage).subscribe({
        next: () => {
          this.translate.use(selectedLanguage);
          //window.location.reload(); // This will refresh the page in order to update the token of the new locale
        },
        error: (error) => {
          console.error('Error updating locale:', error);
        },
      });
    }
  }

  logoPress() {
    this.router.navigate(['/ehr/' + this?.menuList?.[0]?.path]);
  }
}
