import { Component, Input } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { ICoding } from 'src/app/interfaces/ICoding';

@Component({
  selector: 'app-address',
  templateUrl: './address.component.html',
  styleUrls: ['./address.component.css'],
})
export class AddressComponent {
  @Input() formSubmitted?: boolean = false;

  @Input() addressForm: FormGroup = new FormGroup({});

  // Value Sets
  @Input() countriesList: ICoding[] = [];

  public street?: string;
  public streetNUmber?: string;
  public apartmentNumber?: string;
  public town?: string;
  public postcode?: string;
  public district?: string;
  public country?: string = '';

  constructor(private readonly formBuilder: FormBuilder) {}

  ngOnInit() {
    this.addressForm.addControl(
      'street',
      this.formBuilder.control('', Validators.required)
    );
    this.addressForm.addControl(
      'streetNumber',
      this.formBuilder.control('', Validators.required)
    );
    this.addressForm.addControl(
      'apartmentNumber',
      this.formBuilder.control('')
    );
    this.addressForm.addControl(
      'town',
      this.formBuilder.control('', Validators.required)
    );
    this.addressForm.addControl(
      'postcode',
      this.formBuilder.control('', Validators.required)
    );
    this.addressForm.addControl(
      'district',
      this.formBuilder.control('', Validators.required)
    );
    this.addressForm.addControl(
      'country',
      this.formBuilder.control('', Validators.required)
    );
  }
}
