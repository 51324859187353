// user-management-edit.component.ts

import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import Swal from 'sweetalert2';
import { IUserManagement } from '../../../../interfaces/IUserManagement';
import { IUserManagementEdit } from '../../../../interfaces/IUserManagementEdit';
import { UserManagementService } from '../../../../services/userManagement.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-user-management-edit',
  templateUrl: './user-management-edit.component.html',
  styleUrls: ['./user-management-edit.component.css'],
})
export class UserManagementEditComponent implements OnInit {
  formGroup!: FormGroup;
  roles: any[] = [];
  locales: any[] = [];
  loading = false;

  editedUser: IUserManagement = {
    id: '',
    username: '',
    firstName: '',
    lastName: '',
    roles: '',
    locale: '',
    email: '',
    phoneNumber: '',
    requiredActions: false,
  };

  constructor(
    private readonly userManagement: UserManagementService,
    private readonly router: Router,
    private readonly route: ActivatedRoute,
    private formBuilder: FormBuilder
  ) {}

  ngOnInit() {
    this.fetchDropdownData();
    this.fetchUsers();
    this.buildForm();
  }

  buildForm() {
    this.formGroup = this.formBuilder.group({
      username: [this.editedUser.username, Validators.required],
      firstName: [this.editedUser.firstName, Validators.required],
      lastName: [this.editedUser.lastName, Validators.required],
      email: [this.editedUser.email, [Validators.required, Validators.email]],
      phoneNumber: [this.editedUser.phoneNumber, Validators.required],
      roles: [this.editedUser.roles, Validators.required],
      locale: [this.editedUser.locale, Validators.required],
    });
  }

  fetchUsers() {
    const userId = this.route.snapshot.queryParams['id'];

    this.loading = true; // Set loading to true

    this.userManagement.getUserById(userId).subscribe({
      next: (response) => {
        this.editedUser = { ...response.data };

        this.editedUser.roles =
          this.roles.find((role) => role.name === this.editedUser.roles)?.id ||
          null;
        this.editedUser.locale =
          this.locales.find((locale) => locale.name === this.editedUser.locale)
            ?.id || null;
        this.buildForm();
      },
      error: (err) => {
        console.error('Error fetching user details:', err);
      },
      complete: () => {
        this.loading = false;
      },
    });
  }

  fetchDropdownData() {
    this.userManagement.getDropdownData().subscribe({
      next: (data) => {
        this.roles = data.data.roles;
        this.locales = data.data.locale;

        this.editedUser.roles = this.roles.length > 0 ? this.roles[0].id : null;
        this.editedUser.locale =
          this.locales.length > 0 ? this.locales[0].id : null;
        this.buildForm();
      },
      error: (err) => {
        console.error('Error fetching dropdown data:', err);
      },
    });
  }

  sendMail() {
    const userId = this.route.snapshot.queryParams['id'];

    this.userManagement.sendMailVerification(userId).subscribe({
      next: (response) => {
        Swal.fire({
          text: 'Mail verification sent successfully!',
          toast: true,
          position: 'bottom-end',
          showCancelButton: false,
          showConfirmButton: false,
          color: 'white',
          background: '#0d9488',
          timer: 3000,
        });
        console.log('Mail verification sent successfully:', response);
      },
      error: (err) => {
        Swal.fire({
          text: `Unable to send mail verification! ${err.error.message}`,
          toast: true,
          position: 'bottom-end',
          showCancelButton: false,
          showConfirmButton: false,
          color: 'white',
          background: '#ff6969',
          timer: 3000,
        });
        console.error('Error sending mail verification:', err);
      },
    });
  }

  saveChanges() {
    if (this.formGroup.valid) {
      const userId = this.route.snapshot.queryParams['id'];

      this.loading = true;

      const selectedRoleId = this.formGroup.get('roles')?.value;
      const selectedRole = this.roles.find((role) => role.id == selectedRoleId);
      this.editedUser.roles = selectedRole?.id;

      const selectedLocaleId = this.formGroup.get('locale')?.value;
      const selectedLocale = this.locales.find(
        (locale) => locale.id == selectedLocaleId
      );
      this.editedUser.locale = selectedLocale?.name;

      console.log(selectedLocale);
      const requestBody: IUserManagementEdit = {
        userUpdate: {
          username: this.editedUser.username,
          firstName: this.editedUser.firstName,
          lastName: this.editedUser.lastName,
          email: this.editedUser.email,
          attributes: {
            locale: this.editedUser.locale,
            phoneNumber: this.editedUser.phoneNumber,
          },
        },
        roleInfo: {
          id: this.editedUser.roles,
          name: selectedRole.name,
        },
      };

      console.log(requestBody);

      this.userManagement
        .saveUser(userId, requestBody)
        .subscribe({
          next: (response) => {
            Swal.fire({
              text: 'User updated successfully!',
              toast: true,
              position: 'bottom-end',
              showCancelButton: false,
              showConfirmButton: false,
              color: 'white',
              background: '#0d9488',
              timer: 3000,
            });

            //initialized = true;
            this.router.navigate(['ehr/userManagement']);
          },
          error: (err) => {
            Swal.fire({
              text: `Error saving user changes! ${err.error.message}`,
              toast: true,
              position: 'bottom-end',
              showCancelButton: false,
              showConfirmButton: false,
              color: 'white',
              background: '#ff6969',
              timer: 3000,
            });
            console.error('Error saving user changes:', err);
          },
        })
        .add(() => {
          this.loading = false;
        });
    } else {
      Swal.fire({
        text: `Please fill in all required fields with valid data.`,
        toast: true,
        position: 'bottom-end',
        showCancelButton: false,
        showConfirmButton: false,
        color: 'white',
        background: '#ffd470',
        timer: 3000,
      });
    }
  }

  cancelEdit() {
    this.router.navigate(['ehr/userManagement']);
  }
}
