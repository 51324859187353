import { Component, Input } from '@angular/core';
import { IModule } from 'src/app/interfaces/IModule';
import { EpisodeOfCareService } from 'src/app/services/episodeOfCare.service';
import { paths } from 'src/component-paths/component-paths';

@Component({
  selector: 'app-medical-problems-section',
  templateUrl: './medical-problems-section.component.html',
  styleUrls: ['./medical-problems-section.component.css'],
})
export class MedicalProblemsSectionComponent {
  @Input() submenu?: IModule[];
  componentPaths = paths;

  constructor(private readonly eocService: EpisodeOfCareService) {}

  ngOnInit() {}
}
