import { NgModule } from '@angular/core';
import { RouteReuseStrategy, RouterModule, Routes } from '@angular/router';
import { EhrComponent } from './components/ehr/ehr.component';
import { LoginComponent } from './components/ehr/login/login.component';
import { AuthGuard } from './guards/auth.guard';
import { InPatientsComponent } from './components/ehr/in-patients/in-patients.component';
import { DashboardComponent } from './components/ehr/dashboard/dashboard.component';
import { PatientsComponent } from './components/ehr/patients/patients.component';
import { CustomReuseStrategy } from './CustomReuseStrategy';
import { RegisterPatientComponent } from './components/ehr/register-patient/register-patient.component';
import { ProfileComponent } from './components/ehr/patients/profile/profile.component';
import { EpisodeOfCareComponent } from './components/ehr/patients/episode-of-care/episode-of-care.component';
import { EocGuard } from './guards/eoc.guard';
import { UserManagementComponent } from './components/ehr/user-management/user-management.component';
import { UserManagementEditComponent } from './components/ehr/user-management/user-management-edit/user-management-edit.component';
import { UserManagementNewComponent } from './components/ehr/user-management/user-management-new/user-management-new.component';
import { ImportComponent } from './components/ehr/import/import.component';

const routes: Routes = [
  {
    path: 'ehr',
    component: EhrComponent,
    data: { reuse: true },
    canActivate: [AuthGuard],
    children: [
      {
        path: 'dashboard',
        component: DashboardComponent,
        data: { reuse: false },
      },
      {
        path: 'inPatients',
        component: InPatientsComponent,
        data: { reuse: true },
      },
      {
        path: 'allPatients',
        component: PatientsComponent,
        data: { reuse: true },
      },
      {
        path: 'userManagement',
        component: UserManagementComponent,
        data: { reuse: false },
      },
      {
        path: 'import',
        component: ImportComponent,
        data: { reuse: false },
      },
      {
        path: 'userProfile',
        component: UserManagementEditComponent,
        data: { reuse: false },
      },
      {
        path: 'userRegistration',
        component: UserManagementNewComponent,
        data: { reuse: false },
      },
      {
        path: 'register-patient',
        component: RegisterPatientComponent,
        data: { reuse: false },
      },
      {
        path: 'profile',
        component: ProfileComponent,
        data: { reuse: false },
      },
      {
        path: 'episodeOfCare',
        component: EpisodeOfCareComponent,
        data: { reuse: false },
        canActivate: [EocGuard],
      },
      {
        path: 'inPatientView',
        component: EpisodeOfCareComponent,
        data: { reuse: false },
        canActivate: [EocGuard],
      },
    ],
  },
  {
    path: 'login',
    component: LoginComponent,
    data: { reuse: false },
  },
  { path: '**', redirectTo: '/login', data: { reuse: false } },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
  providers: [
    {
      provide: RouteReuseStrategy,
      useClass: CustomReuseStrategy,
    },
  ],
  declarations: [],
})
export class AppRoutingModule {}
