import { Component, Input, SimpleChanges } from '@angular/core';
import { Chart } from 'chart.js/auto';
import { IGenericServiceResponse } from '../../../../interfaces/IServiceResponse';
import { environment } from '../../../../../environments/environment';
import { IDashboardChart } from '../../../../interfaces/Dashboard/IDashboardChart';
import { HttpClient } from '@angular/common/http';
import { IDateTimeRange } from 'src/app/interfaces/Dashboard/IDateTimeRange';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-dashboard-bed-availability-by-day',
  templateUrl: './dashboard-bed-availability-by-day.component.html',
  styleUrls: ['./dashboard-bed-availability-by-day.component.css'],
})
export class DashboardBedAvailabilityByDayComponent {
  @Input() dateTimeRange: IDateTimeRange | null = null; // Input to receive the selected date range

  constructor(private http: HttpClient,private translate: TranslateService) {}

  public chartData: IDashboardChart | null = null;

  ngAfterViewInit(): void {
    this.fetchData();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['dateTimeRange'] && !changes['dateTimeRange'].firstChange) {
      this.fetchData(); // Fetch data whenever dateTimeRange changes
    }
  }

  BarChart(): void {
    const maxCapacityDataSet = this.chartData?.dataSet?.[0].data;
    const dailyOccupancyDataSet = this.chartData?.dataSet?.[1].data;

    const maxCapacityNum = maxCapacityDataSet?.map(Number);
    const dailyOccupancyNum = dailyOccupancyDataSet?.map(Number);
    const allDataSets = [...maxCapacityNum!, ...dailyOccupancyNum!];
    const maxYAxisValue = Math.max(...allDataSets) + 2; // Adjust buffer as needed

    const data = {
      labels: this.chartData!.labels,
      datasets: [
        {
          label: this.translate.instant('Maximum Capacity'),
          data: maxCapacityDataSet,
          backgroundColor: 'rgba(255, 99, 132, 0.2)',
          borderColor: 'rgba(255, 99, 132, 1)',
          borderWidth: 1,
        },
        {
          label: this.translate.instant('Daily Occupancy'),
          data: dailyOccupancyDataSet,
          backgroundColor: 'rgba(75, 192, 192, 0.2)',
          borderColor: 'rgba(75, 192, 192, 1)',
          borderWidth: 1,
        },
      ],
    };

    new Chart('bedAvailabilityByDay', {
      type: 'line',
      data: data,
      options: {
        scales: {
          y: {
            beginAtZero: true,
            max: maxYAxisValue,
          },
        },
        responsive: true,
        plugins: {
          legend: {
            position: 'top',
          },
          title: {
            display: true,
            text: this.translate.instant('Daily Bed Capacity'),
          },
        },
      },
    });
  }

  fetchData(): void {
    let chartStatus = Chart.getChart('bedAvailabilityByDay');
    if (chartStatus != undefined) {
      chartStatus.destroy();
    }

    this.http
      .post<IGenericServiceResponse<IDashboardChart>>(
        `${environment.BACKEND_URL}Dashboard/CapacityBed`,
        this.dateTimeRange
      )
      .subscribe((data) => {
        this.chartData = data.data;
        this.BarChart();
      });
  }
}
