import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import {
  IGenericServiceResponse,
  IServiceResponse,
} from '../interfaces/IServiceResponse';
import { environment } from 'src/environments/environment';
import { EpisodeOfCareService } from './episodeOfCare.service';
import { PatientService } from './patient.service';
import Swal from 'sweetalert2';
import { IMedicationDetailed } from '../interfaces/Medication/IMedicationDetailed';
import { IAddMedication } from '../interfaces/Medication/IAddMedication';
import { IBrandNameDropdown } from '../interfaces/Medication/IBrandNameDropdown';
import { IAtcDropdown } from '../interfaces/Medication/IAtcDropdown';
import { IActiveSubstanceDropdown } from '../interfaces/Medication/IActiveSubstanceDropdown';
import { IGetList } from '../interfaces/Get/IGetList';
import { IMedicationDropdown } from '../interfaces/Medication/IMedicationDropdown';

@Injectable()
export class CurrentMedicationService {
  public medicationOpenSub = new BehaviorSubject<boolean>(false);

  public medicationEditSub = new BehaviorSubject<
    IMedicationDetailed | undefined
  >(undefined);

  public currentMedicationDataSub = new BehaviorSubject<
    IGetList<IMedicationDetailed>
  >({});

  public currentMedicationLoadingSub = new BehaviorSubject<boolean>(false);

  public noKnownLoadingSub = new BehaviorSubject<boolean>(false);

  constructor(
    private readonly _http: HttpClient,
    private readonly eocService: EpisodeOfCareService,
    private readonly patientService: PatientService
  ) {}

  async getData() {
    this.currentMedicationLoadingSub.next(true);
    this.getCurrentMedication(
      this.patientService.patientIDsignal(),
      this.eocService.currentVisitId()
    ).subscribe({
      next: (response) => {
        this.currentMedicationDataSub.next(response?.data);
        this.currentMedicationLoadingSub.next(false);
        this.noKnownLoadingSub.next(false);
      },
      error: (err) => {
        Swal.fire({
          text: 'Unable to retrieve current medications!',
          toast: true,
          position: 'bottom-end',
          showCancelButton: false,
          showConfirmButton: false,
          color: 'white',
          background: '#ff6969',
          timer: 3000,
        });
        console.error('Error fetching current medications:', err);
      },
    });
  }

  getCurrentMedication(
    patientId: string,
    visitId?: string
  ): Observable<IGenericServiceResponse<IGetList<IMedicationDetailed>>> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    if (visitId) {
      return this._http.get<
        IGenericServiceResponse<IGetList<IMedicationDetailed>>
      >(
        `${environment.BACKEND_URL}Medication/CurrentMedications/${patientId}?visitId=${visitId}`,
        {
          headers: headers,
        }
      );
    } else {
      return this._http.get<
        IGenericServiceResponse<IGetList<IMedicationDetailed>>
      >(
        `${environment.BACKEND_URL}Medication/CurrentMedications/${patientId}`,
        {
          headers: headers,
        }
      );
    }
  }

  getMedicationDropdown(): Observable<
    IGenericServiceResponse<IMedicationDropdown>
  > {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    return this._http.get<IGenericServiceResponse<IMedicationDropdown>>(
      `${environment.BACKEND_URL}Medication/DropdownData`,
      {
        headers: headers,
      }
    );
  }

  getBrandNameDropdown(): Observable<
    IGenericServiceResponse<IBrandNameDropdown>
  > {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    return this._http.get<IGenericServiceResponse<IBrandNameDropdown>>(
      `${environment.BACKEND_URL}Pharmacy/DropdownData/BrandName`,
      {
        headers: headers,
      }
    );
  }

  getAtcDropdown(): Observable<IGenericServiceResponse<IAtcDropdown>> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    return this._http.get<IGenericServiceResponse<IAtcDropdown>>(
      `${environment.BACKEND_URL}Pharmacy/DropdownData/AtcCode`,
      {
        headers: headers,
      }
    );
  }

  getActiveSubstanceDropdown(): Observable<
    IGenericServiceResponse<IActiveSubstanceDropdown>
  > {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    return this._http.get<IGenericServiceResponse<IActiveSubstanceDropdown>>(
      `${environment.BACKEND_URL}Pharmacy/DropdownData/ActiveSubstance`,
      {
        headers: headers,
      }
    );
  }

  addMedication(currentMedicationsObj: IAddMedication) {
    currentMedicationsObj.visitId = this.eocService.currentVisitId();
    currentMedicationsObj.patientId = this.patientService.patientIDsignal();

    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });

    return this._http.post(
      `${environment.BACKEND_URL}Medication`,
      currentMedicationsObj,
      {
        headers: headers,
      }
    );
  }

  addNoKnownCurrentMedication() {
    const object = {
      patientId: this.patientService.patientIDsignal(),
      visitId: this.eocService.currentVisitId(),
    };

    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });

    return this._http.post(
      `${environment.BACKEND_URL}Medication/NoKnownMedications`,
      object,
      {
        headers: headers,
      }
    );
  }

  editMedication(currentMedicationsObj: IAddMedication) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });

    return this._http.put(
      `${environment.BACKEND_URL}Medication/${currentMedicationsObj.id}`,
      currentMedicationsObj,
      {
        headers: headers,
      }
    );
  }

  deleteCurrentMedication(
    currentMedicationId: string
  ): Observable<IServiceResponse> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });

    return this._http.delete<IServiceResponse>(
      `${environment.BACKEND_URL}Medication/${currentMedicationId}`,
      {
        headers: headers,
      }
    );
  }

  deleteNoKnownCurrentMedication(
    currentMedicationId: string
  ): Observable<IServiceResponse> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });

    return this._http.delete<IServiceResponse>(
      `${environment.BACKEND_URL}Medication/NoKnownMedications/${currentMedicationId}`,
      {
        headers: headers,
      }
    );
  }
}
