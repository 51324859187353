import { AfterViewInit, Component } from '@angular/core';
import { Chart } from 'chart.js/auto';
import { InpatientService } from '../../../../services/inpatient.service';
import { IDashboardChart } from '../../../../interfaces/Dashboard/IDashboardChart';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-dashboard-inpatient-by-age',
  templateUrl: './dashboard-inpatient-by-age.component.html',
  styleUrls: ['./dashboard-inpatient-by-age.component.css'],
})
export class DashboardInpatientByAgeComponent implements AfterViewInit {
  constructor(private readonly inPatientService: InpatientService,private translate: TranslateService) {}
  ngAfterViewInit(): void {
    this.getData();
  }

  public InPatientAge: IDashboardChart | null = null;

  BarChart(): void {
    const dataSet = this.InPatientAge?.dataSet?.[0].data;
    const datasets = this.InPatientAge?.dataSet || [];
    const maxYAxisValue = this.setBuffer(dataSet!);

    const data = {
      labels: [''],
      datasets: datasets.map((dataset, index) => ({
        label: dataset.label,
        data: dataset.data,
        backgroundColor: [dataset.backgroundColor],
        borderColor: 'rgba(128, 128, 128, 0.6)',
        borderRadius: 10,
        borderWidth: 1,
      })),
    };

    new Chart('inPatientAge', {
      type: 'bar',
      data: data,
      options: {
        scales: {
          y: {
            beginAtZero: true,
            max: maxYAxisValue,
            ticks: {
              stepSize: 1,
            },
          },
        },
        plugins: {
          title: {
            display: true,
            text: this.translate.instant('Inpatient Age'),
          },
        },
      },
    });
  }

  setBuffer(data: string[]): number {
    // Convert strings to numeric values
    const numericData = data.map((str) => parseFloat(str));

    // Calculate the maximum value in the numeric dataset
    const maxDataValue = Math.max(...numericData);

    // Set a buffer value to make the maximum value of the chart slightly higher
    const buffer = 3; // You can adjust this value as needed

    // Calculate the maximum value for the chart's y-axis
    const maxYAxisValue = maxDataValue + buffer;

    return maxYAxisValue;
  }

  getData() {
    this.inPatientService.getDashboardInPatientByAge().subscribe({
      next: (response) => {
        this.InPatientAge = response?.data;
        this.BarChart();
      },
      error: (err) => {
        console.log(err);
      },
    });
  }
}
