import Swal from 'sweetalert2';
import { Subject, takeUntil } from 'rxjs';
import { Component } from '@angular/core';
import { BooleanInput } from 'ng-zorro-antd/core/types';
import { IPreviewDraft } from 'src/app/interfaces/Detailed/IPreviewDraftDetailed';
import { EpisodeOfCareService } from 'src/app/services/episodeOfCare.service';
import { AllergiesExpandComponent } from '../components/alerts-section/allergies/allergies-expand/allergies-expand.component';
import { ProcedureExpandComponent } from '../components/medical-problems-section/procedure/procedure-expand/procedure-expand.component';
import { MedicalDeviceExpandComponent } from '../components/medical-problems-section/medical-device/medical-device-expand/medical-device-expand.component';
import { CurrentProblemsExpandComponent } from '../components/medical-problems-section/current-problems/current-problems-expand/current-problems-expand.component';
import { Router } from '@angular/router';
import { NzSelectOptionInterface } from 'ng-zorro-antd/select';
import {
  IDynamicItem,
  IDynamicTable,
} from 'src/app/interfaces/DynamicTable/IDynamicDetailed';
import { ResolvedProblemsExpandComponent } from '../components/medical-history-section/resolved-problems/resolved-problems-expand/resolved-problems-expand.component';

@Component({
  selector: 'app-preview-summary',
  templateUrl: './preview-summary.component.html',
  styleUrls: ['./preview-summary.component.css'],
})
export class PreviewSummaryComponent {
  public allergiesExpandComp = AllergiesExpandComponent;
  public procedureExpandComp = ProcedureExpandComponent;
  public medicalDeviceExpandComp = MedicalDeviceExpandComponent;
  public problemExpandComp = CurrentProblemsExpandComponent;
  public resolvedExpandComp = ResolvedProblemsExpandComponent;

  private destroy$ = new Subject<void>();

  data?: IPreviewDraft;

  switchValue?: boolean = false;

  pdValue?: number;

  pdList: NzSelectOptionInterface[] = [];

  noteValue?: string = '';

  isLoading: boolean = false;
  isPdLoading: BooleanInput = false;

  constructor(
    private readonly eocService: EpisodeOfCareService,
    private readonly router: Router
  ) {}

  handleEndedValue(value: boolean) {
    this.eocService.eocEndedSub.next(value);
  }

  setPD(id: number) {
    this.eocService.setPD(id);
  }

  setNote(note: any) {
    this.eocService.setNote(note);
  }

  isInpatientView() {
    return (
      !(
        this.eocService.currentEocId() === null ||
        this.eocService.currentEocId() === ''
      ) && this.router.url.includes('inPatientView')
    );
  }

  ngOnInit() {
    this.eocService.unvalidatePreview();
    this.eocService.unsetNote();
    this.eocService.unsetPd();
    this.eocService.eocEndedSub.next(false);

    this.eocService.potentialDiagnosisSub
      .pipe(takeUntil(this.destroy$))
      .subscribe((data: number | undefined) => {
        this.pdValue = data || undefined;
      });

    this.isLoading = true;
    this.isPdLoading = true;
    this.eocService
      .getPreviewDraft(this.eocService.currentVisitId())
      ?.subscribe({
        next: (d) => {
          this.data = d?.data;
          this.isLoading = false;

          if (d?.data?.getPotentialDiagnosis?.id) {
            this.eocService.setPD(d?.data?.getPotentialDiagnosis?.id);
          }

          if (d?.data?.isEmpty == false) {
            this.eocService.validatePreview();
          }
        },
        error: (err) => {
          Swal.fire({
            text: 'Unable to retrieve Draft Records!',
            toast: true,
            position: 'bottom-end',
            showCancelButton: false,
            showConfirmButton: false,
            color: 'white',
            background: '#ff6969',
            timer: 3000,
          });
          console.log(err);
        },
      });

    this.eocService.getPotentialDiagnosis().subscribe({
      next: (d) => {
        this.pdList = d?.data?.map((d) => {
          return {
            label: d.name,
            value: d.id,
          };
        });
        this.isPdLoading = false;
      },
      error: (err) => {
        Swal.fire({
          text: 'Unable to retrieve Potential Diagnosis!',
          toast: true,
          position: 'bottom-end',
          showCancelButton: false,
          showConfirmButton: false,
          color: 'white',
          background: '#ff6969',
          timer: 3000,
        });
        console.log(err);
      },
    });
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  getLaboratoryData(
    laboratoryData: IDynamicTable<IDynamicItem>[],
    groupId: number
  ) {
    return laboratoryData.filter((dataItem) => dataItem.groupId == groupId);
  }
}
