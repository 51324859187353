import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Router } from '@angular/router';
import { Subject, takeUntil } from 'rxjs';
import { IModule } from 'src/app/interfaces/IModule';
import { CurrentMedicationService } from 'src/app/services/currentMedication.service';
import { EpisodeOfCareService } from 'src/app/services/episodeOfCare.service';
import { MedicationExpandComponent } from './medication-expand/medication-expand.component';
import { paths } from 'src/component-paths/component-paths';

@Component({
  selector: 'app-medication-summary-section',
  templateUrl: './medication-summary-section.component.html',
  styleUrls: ['./medication-summary-section.component.css'],
})
export class MedicationSummarySectionComponent {
  @Input() submenu?: IModule[];
  componentPaths = paths;

  private destroy$ = new Subject<void>();

  constructor(
    private readonly currentMedicationService: CurrentMedicationService,
    private readonly eocService: EpisodeOfCareService,
    private readonly router: Router
  ) {}

  addScreen?: boolean = false;

  ngOnInit() {
    this.currentMedicationService.medicationOpenSub
      .pipe(takeUntil(this.destroy$))
      .subscribe((data) => {
        this.addScreen = data;
      });
  }

  ngOnDestroy() {
    this.currentMedicationService.medicationOpenSub.next(false);
    this.destroy$.next();
    this.destroy$.complete();
  }

  handleButton() {
    this.currentMedicationService.medicationEditSub.next(undefined);
    this.currentMedicationService.medicationOpenSub.next(true);
  }

  isEOC() {
    return (
      !(
        this.eocService.currentEocId() === null ||
        this.eocService.currentEocId() === ''
      ) && this.router.url.includes('episodeOfCare')
    );
  }

  isInpatientView() {
    return (
      !(
        this.eocService.currentEocId() === null ||
        this.eocService.currentEocId() === ''
      ) && this.router.url.includes('inPatientView')
    );
  }
}
