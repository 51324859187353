// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.app-body {
  height: 100vh;
  width: 100%;
}

/* ::ng-deep .swal2-popup ::ng-deep .swal2-styled:focus {
  box-shadow: none !important;
}

::ng-deep div:where(.swal2-container) ::ng-deep div:where(.swal2-popup) {
  border-radius: 10px !important;
}

::ng-deep
  div:where(.swal2-container)
  ::ng-deep
  div:where(.swal2-html-container) {
  font-size: 20px !important;
  margin: 1.5em 1.5em 1.5em !important;
}

::ng-deep div:where(.swal2-container) ::ng-deep div:where(.swal2-actions) {
  margin: 0px !important;
} */
`, "",{"version":3,"sources":["webpack://./src/app/app.component.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,WAAW;AACb;;AAEA;;;;;;;;;;;;;;;;;;GAkBG","sourcesContent":[".app-body {\r\n  height: 100vh;\r\n  width: 100%;\r\n}\r\n\r\n/* ::ng-deep .swal2-popup ::ng-deep .swal2-styled:focus {\r\n  box-shadow: none !important;\r\n}\r\n\r\n::ng-deep div:where(.swal2-container) ::ng-deep div:where(.swal2-popup) {\r\n  border-radius: 10px !important;\r\n}\r\n\r\n::ng-deep\r\n  div:where(.swal2-container)\r\n  ::ng-deep\r\n  div:where(.swal2-html-container) {\r\n  font-size: 20px !important;\r\n  margin: 1.5em 1.5em 1.5em !important;\r\n}\r\n\r\n::ng-deep div:where(.swal2-container) ::ng-deep div:where(.swal2-actions) {\r\n  margin: 0px !important;\r\n} */\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
