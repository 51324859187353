// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#divChart{
  display: flex;
  width: 44vw !important;
  height: 450px!important;
}

.chart-container {
  border-radius: 8px; /* Adjust the border radius as needed */
 
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Shadow effect */
  overflow: hidden; /* Hide overflow if necessary */
  padding: 10px;
  background-color: white;
}

#bedAvailabilityByDay {
  width: 100%; /* Make sure the canvas fills its container */
  height: auto; /* Allow canvas to adjust height as needed */
}
`, "",{"version":3,"sources":["webpack://./src/app/components/ehr/dashboard/dashboard-bed-availability-by-day/dashboard-bed-availability-by-day.component.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,uBAAuB;AACzB;;AAEA;EACE,kBAAkB,EAAE,uCAAuC;;EAE3D,wCAAwC,EAAE,kBAAkB;EAC5D,gBAAgB,EAAE,+BAA+B;EACjD,aAAa;EACb,uBAAuB;AACzB;;AAEA;EACE,WAAW,EAAE,6CAA6C;EAC1D,YAAY,EAAE,4CAA4C;AAC5D","sourcesContent":["#divChart{\r\n  display: flex;\r\n  width: 44vw !important;\r\n  height: 450px!important;\r\n}\r\n\r\n.chart-container {\r\n  border-radius: 8px; /* Adjust the border radius as needed */\r\n \r\n  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Shadow effect */\r\n  overflow: hidden; /* Hide overflow if necessary */\r\n  padding: 10px;\r\n  background-color: white;\r\n}\r\n\r\n#bedAvailabilityByDay {\r\n  width: 100%; /* Make sure the canvas fills its container */\r\n  height: auto; /* Allow canvas to adjust height as needed */\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
