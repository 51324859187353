export const paths = {
  // Sections
  patientSummary: 'patientSummary',
  alerts: 'alerts',
  dailyMonitoring: 'dailyMonitoring',
  medicalProblems: 'medicalProblems',
  medicalHistorySection: 'medicalHistory',
  pregnancy: 'pregnancy',
  medicationSummary: 'medicationSummary',
  socialHistory: 'socialHistory',
  laboratory: 'laboratory',
  imaging: 'imaging',
  healthCarePlan: 'carePlan',
  travelHistory: 'travelHistory',
  episodeOfCare: 'episodeOfCare',
  pilotStudy: 'pilotStudy',
  tracheostomy: 'tracheostomy',
  etiology: 'etiology',
  complication: 'complication',
  comorbidity: 'comorbidity',
  tracheostomy2: 'tracheostomy2',
  patientCharacteristics: 'patientCharacteristics',

  // Sub-items
  vitalSigns: 'vitalSigns',
  arterialBloodGas: 'arterialBloodGas',
  glasgow: 'glasgow',
  allergies: 'allergies',
  medicalAlert: 'medicalAlert',
  vaccinations: 'vaccinations',
  resolvedProblems: 'resolvedProblems',
  medicalHistory: 'medical',
  currentProblems: 'currentProblems',
  devicesAndImplants: 'deviceAndImplants',
  procedures: 'procedures',
  currentMedicines: 'medicines',
  pastMedicines: 'pastMedicines',

  laboratoryFiles: 'laboratoryFiles',
  laboratoryResults: 'laboratoryResults',

  ctParameters: 'cTParameters',
  tubeCharacteristics: 'tubeCharacteristics',
  capnography: 'capnography',
};
