import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import {
  IGenericServiceResponse,
  IServiceResponse,
} from '../interfaces/IServiceResponse';
import { environment } from 'src/environments/environment';
import { EpisodeOfCareService } from './episodeOfCare.service';
import { PatientService } from './patient.service';
import Swal from 'sweetalert2';
import { IMedicalInfoDetailed } from '../interfaces/MedicalInfo/IMedicalInfoDetailed';
import { IAddMedicalInfo } from '../interfaces/MedicalInfo/IAddMedicalInfo';

@Injectable()
export class MedicalAlertService {
  public medicalAlertModalSub = new BehaviorSubject<boolean>(false);

  public medicalAlertEditSub = new BehaviorSubject<
    IMedicalInfoDetailed | undefined
  >(undefined);

  public medicalAlertDataSub = new BehaviorSubject<IMedicalInfoDetailed[]>([]);

  public medicalAlertLoadingSub = new BehaviorSubject<boolean>(false);

  constructor(
    private readonly _http: HttpClient,
    private readonly eocService: EpisodeOfCareService,
    private readonly patientService: PatientService
  ) {}

  async getData() {
    this.medicalAlertLoadingSub.next(true);
    this.getMedicalAlert(
      this.patientService.patientIDsignal(),
      this.eocService.currentVisitId()
    ).subscribe({
      next: (response) => {
        this.medicalAlertDataSub.next(response?.data!);
        this.medicalAlertLoadingSub.next(false);
      },
      error: (err) => {
        Swal.fire({
          text: 'Unable to retrieve medical alerts!',
          toast: true,
          position: 'bottom-end',
          showCancelButton: false,
          showConfirmButton: false,
          color: 'white',
          background: '#ff6969',
          timer: 3000,
        });
        console.error('Error fetching medical alerts:', err);
      },
    });
  }

  getMedicalAlert(
    patientId: string,
    visitId?: string
  ): Observable<IGenericServiceResponse<IMedicalInfoDetailed[]>> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    if (visitId) {
      return this._http.get<IGenericServiceResponse<IMedicalInfoDetailed[]>>(
        `${environment.BACKEND_URL}MedicalAlert/${patientId}?visitId=${visitId}`,
        {
          headers: headers,
        }
      );
    } else {
      return this._http.get<IGenericServiceResponse<IMedicalInfoDetailed[]>>(
        `${environment.BACKEND_URL}MedicalAlert/${patientId}`,
        {
          headers: headers,
        }
      );
    }
  }

  addMedicalAlert(medicalAlertObj: IAddMedicalInfo) {
    medicalAlertObj.visitId = this.eocService.currentVisitId();
    medicalAlertObj.patientId = this.patientService.patientIDsignal();

    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });

    return this._http.post(
      `${environment.BACKEND_URL}MedicalAlert`,
      medicalAlertObj,
      {
        headers: headers,
      }
    );
  }

  editMedicalAlert(medicalAlertObj: IAddMedicalInfo) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });

    return this._http.put(
      `${environment.BACKEND_URL}MedicalAlert/${medicalAlertObj.id}`,
      medicalAlertObj,
      {
        headers: headers,
      }
    );
  }

  deleteMedicalAlert(medicalAlertId: string): Observable<IServiceResponse> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });

    return this._http.delete<IServiceResponse>(
      `${environment.BACKEND_URL}MedicalAlert/${medicalAlertId}`,
      {
        headers: headers,
      }
    );
  }

  deleteMedicalAlertFromTable(medicalAlertId: string) {
    // REMOVE THE MedicalAlert
    this.medicalAlertDataSub.next(
      this.medicalAlertDataSub.value.filter(
        (ma) => ma.id?.toString() != medicalAlertId
      )
    );
  }
}
