import { KeycloakConfig } from 'keycloak-js';

// const keycloakConfigT: KeycloakConfig = {
//   realm: 'SaasEHR',
//   url: 'https://auth.3ahealth.com/',
//   clientId: 'ehr-ui',
// };

const keycloakConfig: KeycloakConfig = {
  realm: 'SaaSEHR',
  url: 'https://acc-auth.3ahealth.com/',
  clientId: 'ehr-ui',
};

export default keycloakConfig;
