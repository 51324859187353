import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { FlexModule } from '@angular/flex-layout';
import { NavigationExtras, Router } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzLayoutModule } from 'ng-zorro-antd/layout';
import { NzTableModule } from 'ng-zorro-antd/table';
import { ReusableTableExpandComponent } from 'src/app/common/reusable-table-expand/reusable-table-expand.component';
import { IGetEpisodesOfCare } from 'src/app/interfaces/IGetEpisodesOfCare';
import { EpisodeOfCareService } from 'src/app/services/episodeOfCare.service';
import { PatientService } from 'src/app/services/patient.service';
import Swal from 'sweetalert2';

@Component({
  standalone: true,
  selector: 'app-visit-expand',
  templateUrl: './visit-expand.component.html',
  styleUrls: ['./visit-expand.component.css'],
  imports: [
    NzLayoutModule,
    NzTableModule,
    TranslateModule,
    CommonModule,
    ReusableTableExpandComponent,
    FlexModule,
    NzIconModule,
    NzButtonModule,
    NzIconModule,
  ],
})
export class VisitExpandComponent {
  @Input() item: IGetEpisodesOfCare = {};

  isVisitloading: boolean = false;

  constructor(
    private readonly router: Router,
    private readonly eocService: EpisodeOfCareService,
    private readonly patientService: PatientService
  ) {}

  isAnyVisitIncomplete(): boolean {
    return (
      (this.item.visits &&
        this.item.visits.some((v) => v && !v.isCompleted && v.isEditable)) ||
      false
    );
  }

  openVisit(eocID: number, visitID: number) {
    this.eocService.currentEocId.set(eocID?.toString());

    this.eocService.currentVisitId.set(visitID?.toString());

    const navigationExtras: NavigationExtras = {
      queryParams: {
        patientID: this.patientService.patientIDsignal(),
      },
    };
    this.item.isHospitalized? 
      this.router.navigate(['/ehr/inPatientView'], navigationExtras) 
      :    
      this.router.navigate(['/ehr/episodeOfCare'], navigationExtras);
    ;
  }

  discardVisit(visitId: number) {
    Swal.fire({
      text: 'Are you sure that you want to discard this visit?',
      showDenyButton: false,
      showCancelButton: true,
      confirmButtonText: 'Ok',
    }).then((result) => {
      if (result.isConfirmed) {
        this.eocService.deleteVisit(visitId.toString()).subscribe({
          next: () => {
            Swal.fire({
              text: 'Visit was discarded successfully!',
              toast: true,
              position: 'bottom-end',
              showCancelButton: false,
              showConfirmButton: false,
              color: 'white',
              background: '#0d9488',
              timer: 3000,
            });

            this.eocService.deleteVisitFromTable(visitId.toString());
          },
          error: (err) => {
            Swal.fire({
              text: 'Unable to discard the visit!',
              toast: true,
              position: 'bottom-end',
              showCancelButton: false,
              showConfirmButton: false,
              color: 'white',
              background: '#ff6969',
              timer: 3000,
            });
          },
        });
      } else if (result.isDenied) {
        Swal.fire('Changes are not saved', '', 'info');
      }
    });
  }

  createNewVisit(eocID: number) {
    Swal.fire({
      text: 'Are you sure that you want to create a new visit?',
      showDenyButton: false,
      showCancelButton: true,
      confirmButtonText: 'Yes',
    }).then((result) => {
      if (result.isConfirmed) {
        this.isVisitloading = true;
        this.eocService.createNewVisit(eocID).subscribe({
          next: (res) => {
            res?.data?.episodeOfCareId?.toString() &&
              this.eocService.currentEocId.set(
                res?.data?.episodeOfCareId?.toString()
              );

            res?.data?.visitId?.toString() &&
              this.eocService.currentVisitId.set(
                res?.data?.visitId?.toString()
              );

            this.isVisitloading = false;

            const navigationExtras: NavigationExtras = {
              queryParams: {
                patientID: this.patientService.patientIDsignal(),
              },
            };
            this.item.isHospitalized?            
              this.router.navigate(['/ehr/inPatientView'], navigationExtras)
            :
              this.router.navigate(['/ehr/episodeOfCare'], navigationExtras)
            ;

            Swal.fire({
              text: 'New Visit created successfully!',
              toast: true,
              position: 'bottom-end',
              showCancelButton: false,
              showConfirmButton: false,
              color: 'white',
              background: '#0d9488',
              timer: 3000,
            });
          },
          error: (err) => {
            Swal.fire({
              text: 'Unable to create New Visit!',
              toast: true,
              position: 'bottom-end',
              showCancelButton: false,
              showConfirmButton: false,
              color: 'white',
              background: '#ff6969',
              timer: 3000,
            });

            console.log('error');
          },
        });
      } else if (result.isDenied) {
        Swal.fire('No new visit created', '', 'info');
      }
    });
  }
}
