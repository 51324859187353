import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import {
  IGenericServiceResponse,
  IServiceResponse,
} from '../interfaces/IServiceResponse';
import { environment } from 'src/environments/environment';
import { EpisodeOfCareService } from './episodeOfCare.service';
import { PatientService } from './patient.service';
import Swal from 'sweetalert2';
import { ICtParametersDetailed } from '../interfaces/CtParameters/ICtParametersDetailed';
import { IAddCtParameters } from '../interfaces/CtParameters/IAddCtParameters';

@Injectable()
export class CtParametersService {
  public ctParametersModalSub = new BehaviorSubject<boolean>(false);

  public ctParametersEditSub = new BehaviorSubject<
    ICtParametersDetailed | undefined
  >(undefined);

  public ctParametersDataSub = new BehaviorSubject<ICtParametersDetailed[]>([]);

  public ctParametersLoadingSub = new BehaviorSubject<boolean>(false);

  constructor(
    private readonly _http: HttpClient,
    private readonly eocService: EpisodeOfCareService,
    private readonly patientService: PatientService
  ) {}

  async getData() {
    this.ctParametersLoadingSub.next(true);
    this.getCtParameters(
      this.patientService.patientIDsignal(),
      this.eocService.currentVisitId()
    ).subscribe({
      next: (response) => {
        this.ctParametersDataSub.next(response?.data!);
        this.ctParametersLoadingSub.next(false);
      },
      error: (err) => {
        Swal.fire({
          text: 'Unable to retrieve 3D-CT Parameters data!',
          toast: true,
          position: 'bottom-end',
          showCancelButton: false,
          showConfirmButton: false,
          color: 'white',
          background: '#ff6969',
          timer: 3000,
        });
        console.error('Error fetching 3D-CT Parameters data:', err);
      },
    });
  }

  getCtParameters(
    patientId: string,
    visitId?: string
  ): Observable<IGenericServiceResponse<ICtParametersDetailed[]>> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    if (visitId) {
      return this._http.get<IGenericServiceResponse<ICtParametersDetailed[]>>(
        `${environment.BACKEND_URL}CtParameter/${patientId}?visitId=${visitId}`,
        {
          headers: headers,
        }
      );
    } else {
      return this._http.get<IGenericServiceResponse<ICtParametersDetailed[]>>(
        `${environment.BACKEND_URL}CtParameter/${patientId}`,
        {
          headers: headers,
        }
      );
    }
  }

  addCtParameters(abgObj: IAddCtParameters) {
    abgObj.visitId = this.eocService.currentVisitId();
    abgObj.patientId = this.patientService.patientIDsignal();

    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });

    return this._http.post(`${environment.BACKEND_URL}CtParameter`, abgObj, {
      headers: headers,
    });
  }

  editCtParameters(abgObj: IAddCtParameters) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });

    return this._http.put(
      `${environment.BACKEND_URL}CtParameter/${abgObj.id}`,
      abgObj,
      {
        headers: headers,
      }
    );
  }

  deleteCtParameters(medicalAlertId: string): Observable<IServiceResponse> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });

    return this._http.delete<IServiceResponse>(
      `${environment.BACKEND_URL}CtParameter/${medicalAlertId}`,
      {
        headers: headers,
      }
    );
  }

  deleteCtParametersFromTable(ctParametersId: string) {
    // REMOVE THE CtParameters record
    this.ctParametersDataSub.next(
      this.ctParametersDataSub.value?.filter(
        (abg) => abg.id?.toString() != ctParametersId
      )
    );
  }
}
