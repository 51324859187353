// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#canvas {
  width: 100% !important;
  height: auto !important;
  aspect-ratio: unset !important;
}

.chart {
  padding: 1em;
  border: 1px solid #d8d8d8;
  background: white;
}
`, "",{"version":3,"sources":["webpack://./src/app/components/ehr/patients/components/daily-monitoring-section/vital-signs/vital-signs-chart/vital-signs-chart.component.css"],"names":[],"mappings":"AAAA;EACE,sBAAsB;EACtB,uBAAuB;EACvB,8BAA8B;AAChC;;AAEA;EACE,YAAY;EACZ,yBAAyB;EACzB,iBAAiB;AACnB","sourcesContent":["#canvas {\r\n  width: 100% !important;\r\n  height: auto !important;\r\n  aspect-ratio: unset !important;\r\n}\r\n\r\n.chart {\r\n  padding: 1em;\r\n  border: 1px solid #d8d8d8;\r\n  background: white;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
