import {Injectable, signal} from "@angular/core";
import {IDefinition} from "../interfaces/IDefinitions";
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {Observable} from "rxjs";
import {IGenericServiceResponse} from "../interfaces/IServiceResponse";
import {environment} from "../../environments/environment";
import {IUserManagement} from "../interfaces/IUserManagement";
import {IUserManagementDropdownData} from "../interfaces/IUserManagementDropdownData";
import {IUserManagementEdit} from "../interfaces/IUserManagementEdit";
import {IUserManagementNew} from "../interfaces/IUserManagementNew";

@Injectable()
export class UserManagementService {
  public userIDsignal = signal('');
  public definitionsSignal = signal([] as IDefinition[]);

  constructor(private _http: HttpClient) {
  }

  getUsers(): Observable<IGenericServiceResponse<IUserManagement[]>> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });

    return this._http.get<IGenericServiceResponse<IUserManagement[]>>(
      `${environment.BACKEND_URL}UserManagement`,
      {
        headers: headers,
      }
    );
  }

  getUserById(
    id: string
  ): Observable<IGenericServiceResponse<IUserManagement>> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });

    return this._http.get<IGenericServiceResponse<IUserManagement>>(
      `${environment.BACKEND_URL}UserManagement/${id}`,
      {
        headers: headers,
      }
    );
  }

  newUser(requestBody: IUserManagementNew): Observable<IGenericServiceResponse<IUserManagement>> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });

    return this._http.post<IGenericServiceResponse<IUserManagement>>(
      `${environment.BACKEND_URL}UserManagement`,
      requestBody,
      {
        headers: headers,
      }
    );
  }


  saveUser(id: string, requestBody: IUserManagementEdit): Observable<IGenericServiceResponse<IUserManagement>> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });

    return this._http.put<IGenericServiceResponse<IUserManagement>>(
      `${environment.BACKEND_URL}UserManagement/${id}`,
      requestBody,
      {
        headers: headers,
      }
    );
  }

  deleteUser(userId: string): Observable<IGenericServiceResponse<any>> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });

    return this._http.delete<IGenericServiceResponse<any>>(
      `${environment.BACKEND_URL}UserManagement/${userId}`,
      {},
    );
  }

  sendMailVerification(userId: string): Observable<IGenericServiceResponse<any>> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });

    return this._http.put<IGenericServiceResponse<any>>(
      `${environment.BACKEND_URL}UserManagement/SendMailVerification/${userId}`,
      {},
      {
        headers: headers,
      }
    );
  }

  getDropdownData(): Observable<IGenericServiceResponse<IUserManagementDropdownData>> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });

    return this._http.get<IGenericServiceResponse<IUserManagementDropdownData>>(
      `${environment.BACKEND_URL}UserManagement/DropdownData`,
      {
        headers: headers,
      }
    );
  }
}

