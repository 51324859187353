// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ant-layout-header {
    font-weight: 500;
    font-size: 18px;
    background-color: #f4f4f6;
    background: #f4f4f6;
    padding: 0px !important;
  }
  
  nz-layout {
    background: #f4f4f6;
  }
  
  .margint {
    margin-top: 1em;
  }
  `, "",{"version":3,"sources":["webpack://./src/app/components/ehr/patients/components/capnography-section/capnography/capnography.component.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;IAChB,eAAe;IACf,yBAAyB;IACzB,mBAAmB;IACnB,uBAAuB;EACzB;;EAEA;IACE,mBAAmB;EACrB;;EAEA;IACE,eAAe;EACjB","sourcesContent":[".ant-layout-header {\r\n    font-weight: 500;\r\n    font-size: 18px;\r\n    background-color: #f4f4f6;\r\n    background: #f4f4f6;\r\n    padding: 0px !important;\r\n  }\r\n  \r\n  nz-layout {\r\n    background: #f4f4f6;\r\n  }\r\n  \r\n  .margint {\r\n    margin-top: 1em;\r\n  }\r\n  "],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
