// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ehr-body {
  width: 100%;
  margin-top: 66px;
}

.container {
  width: 100%;
  height: 100%;
  /* margin-top: 20px;
  margin-bottom: 20px;
  margin-left: 30px;
  margin-right: 30px;
  height: calc(90vh - 40px);
  width: calc(100vw - 60px);
  border: 1px solid red; */
}
`, "",{"version":3,"sources":["webpack://./src/app/components/ehr/ehr.component.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,gBAAgB;AAClB;;AAEA;EACE,WAAW;EACX,YAAY;EACZ;;;;;;0BAMwB;AAC1B","sourcesContent":[".ehr-body {\r\n  width: 100%;\r\n  margin-top: 66px;\r\n}\r\n\r\n.container {\r\n  width: 100%;\r\n  height: 100%;\r\n  /* margin-top: 20px;\r\n  margin-bottom: 20px;\r\n  margin-left: 30px;\r\n  margin-right: 30px;\r\n  height: calc(90vh - 40px);\r\n  width: calc(100vw - 60px);\r\n  border: 1px solid red; */\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
