// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#divChart{
  display: flex;
    align-items: center;
    width: 35vw;
    height: 30vh;
    justify-content: center;
}

.rounded-chart-labels {
  border-radius: 10px!important; /* Set the border radius for the labels */
  background-color: rgba(54, 162, 235, 0.2); /* Set background color for labels */
  padding: 5px 10px; /* Adjust padding for the labels */
}

.chart-container {
  border-radius: 8px; /* Adjust the border radius as needed */

  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Shadow effect */
  overflow: hidden; /* Hide overflow if necessary */
  padding: 10px;
  background-color: white;
}

#inPatientGender {
  width: 100%; /* Make sure the canvas fills its container */
  height: auto; /* Allow canvas to adjust height as needed */
}
`, "",{"version":3,"sources":["webpack://./src/app/components/ehr/dashboard/dashboard-inpatient-by-gender/dashboard-inpatient-by-gender.component.css"],"names":[],"mappings":"AAAA;EACE,aAAa;IACX,mBAAmB;IACnB,WAAW;IACX,YAAY;IACZ,uBAAuB;AAC3B;;AAEA;EACE,6BAA6B,EAAE,yCAAyC;EACxE,yCAAyC,EAAE,oCAAoC;EAC/E,iBAAiB,EAAE,kCAAkC;AACvD;;AAEA;EACE,kBAAkB,EAAE,uCAAuC;;EAE3D,wCAAwC,EAAE,kBAAkB;EAC5D,gBAAgB,EAAE,+BAA+B;EACjD,aAAa;EACb,uBAAuB;AACzB;;AAEA;EACE,WAAW,EAAE,6CAA6C;EAC1D,YAAY,EAAE,4CAA4C;AAC5D","sourcesContent":["#divChart{\r\n  display: flex;\r\n    align-items: center;\r\n    width: 35vw;\r\n    height: 30vh;\r\n    justify-content: center;\r\n}\r\n\r\n.rounded-chart-labels {\r\n  border-radius: 10px!important; /* Set the border radius for the labels */\r\n  background-color: rgba(54, 162, 235, 0.2); /* Set background color for labels */\r\n  padding: 5px 10px; /* Adjust padding for the labels */\r\n}\r\n\r\n.chart-container {\r\n  border-radius: 8px; /* Adjust the border radius as needed */\r\n\r\n  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Shadow effect */\r\n  overflow: hidden; /* Hide overflow if necessary */\r\n  padding: 10px;\r\n  background-color: white;\r\n}\r\n\r\n#inPatientGender {\r\n  width: 100%; /* Make sure the canvas fills its container */\r\n  height: auto; /* Allow canvas to adjust height as needed */\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
