import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import {
  IGenericServiceResponse,
  IServiceResponse,
} from '../interfaces/IServiceResponse';
import { IGetList } from '../interfaces/Get/IGetList';
import { environment } from 'src/environments/environment';
import { EpisodeOfCareService } from './episodeOfCare.service';
import { PatientService } from './patient.service';
import Swal from 'sweetalert2';
import { IProblemsDetailed } from '../interfaces/Problems/IProblemsDetailed';
import { IAddProblem } from '../interfaces/Problems/IAddProblem';
import { IProblemDropdown } from '../interfaces/Problems/IProblemDropdown';
import { IProblemProcedureDropdown } from '../interfaces/Problems/IProblemProcedureDropdown';
import { IProblemDisorderDropdown } from '../interfaces/Problems/IProblemDisorderDropdown';

@Injectable()
export class CurrentProblemsService {
  public currentProblemModalSub = new BehaviorSubject<boolean>(false);

  public currentProblemEditSub = new BehaviorSubject<
    IProblemsDetailed | undefined
  >(undefined);

  public currentProblemDataSub = new BehaviorSubject<
    IGetList<IProblemsDetailed>
  >({});

  public currentProblemLoadingSub = new BehaviorSubject<boolean>(false);

  public currentProblemNoKnownLoadingSub = new BehaviorSubject<boolean>(false);

  constructor(
    private readonly _http: HttpClient,
    private readonly eocService: EpisodeOfCareService,
    private readonly patientService: PatientService
  ) {}

  async getData() {
    this.currentProblemLoadingSub.next(true);
    await this.getProblems(
      this.patientService.patientIDsignal(),
      this.eocService.currentVisitId()
    ).subscribe({
      next: (response) => {
        this.currentProblemDataSub.next(response?.data!);
        this.currentProblemLoadingSub.next(false);
        this.currentProblemNoKnownLoadingSub.next(false);
      },
      error: (err) => {
        Swal.fire({
          text: 'Unable to retrieve problems!',
          toast: true,
          position: 'bottom-end',
          showCancelButton: false,
          showConfirmButton: false,
          color: 'white',
          background: '#ff6969',
          timer: 3000,
        });
        console.error('Error fetching current problems:', err);
      },
    });
  }

  getProblems(
    patientId: string,
    visitId?: string
  ): Observable<IGenericServiceResponse<IGetList<IProblemsDetailed>>> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    if (visitId) {
      return this._http.get<
        IGenericServiceResponse<IGetList<IProblemsDetailed>>
      >(
        `${environment.BACKEND_URL}Problems/CurrentProblems/${patientId}?visitId=${visitId}`,
        {
          headers: headers,
        }
      );
    } else {
      return this._http.get<
        IGenericServiceResponse<IGetList<IProblemsDetailed>>
      >(`${environment.BACKEND_URL}Problems/CurrentProblems/${patientId}`, {
        headers: headers,
      });
    }
  }

  getProblemDropdown(): Observable<IGenericServiceResponse<IProblemDropdown>> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    return this._http.get<IGenericServiceResponse<IProblemDropdown>>(
      `${environment.BACKEND_URL}Problems/DropdownData`,
      {
        headers: headers,
      }
    );
  }

  getProcedureDropdown(): Observable<
    IGenericServiceResponse<IProblemProcedureDropdown>
  > {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    return this._http.get<IGenericServiceResponse<IProblemProcedureDropdown>>(
      `${environment.BACKEND_URL}Problems/DropdownData/RareDisease`,
      {
        headers: headers,
      }
    );
  }

  getDisorderDropdown(): Observable<
    IGenericServiceResponse<IProblemDisorderDropdown>
  > {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    return this._http.get<IGenericServiceResponse<IProblemDisorderDropdown>>(
      `${environment.BACKEND_URL}Problems/DropdownData/IllnessAndDisorder`,
      {
        headers: headers,
      }
    );
  }

  addProblem(problemObj: IAddProblem) {
    problemObj.visitId = this.eocService.currentVisitId();
    problemObj.patientId = this.patientService.patientIDsignal();

    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });

    return this._http.post(`${environment.BACKEND_URL}Problems`, problemObj, {
      headers: headers,
    });
  }

  addNoKnownProblem() {
    const object = {
      patientId: this.patientService.patientIDsignal(),
      visitId: this.eocService.currentVisitId(),
    };

    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });

    return this._http.post(
      `${environment.BACKEND_URL}Problems/NoKnownProblems`,
      object,
      {
        headers: headers,
      }
    );
  }

  editProblem(problemObj: IAddProblem) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });

    return this._http.put(
      `${environment.BACKEND_URL}Problems/${problemObj.id}`,
      problemObj,
      {
        headers: headers,
      }
    );
  }

  deleteProblems(problemId: string): Observable<IServiceResponse> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });

    return this._http.delete<IServiceResponse>(
      `${environment.BACKEND_URL}Problems/${problemId}`,
      {
        headers: headers,
      }
    );
  }

  deleteNoKnownProblems(problemId: string): Observable<IServiceResponse> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });

    return this._http.delete<IServiceResponse>(
      `${environment.BACKEND_URL}Problems/NoKnownProblems/${problemId}`,
      {
        headers: headers,
      }
    );
  }
}
