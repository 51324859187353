// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ant-layout-header {
  font-weight: 500;
  font-size: 18px;
  background-color: #f4f4f6;
  background: #f4f4f6;
  padding: 0px !important;
}

nz-layout {
  background: #f4f4f6;
}

nz-header {
  background-color: white !important;
}

.ant-table-pagination {
  background-color: white !important;
}

.ant-table-wrapper {
  background-color: white !important;
}

::ng-deep .ant-table-small {
  border: 1px solid #f0f0f0 !important;
}
`, "",{"version":3,"sources":["webpack://./src/app/common/reusable-table-expand/reusable-table-expand.component.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;EAChB,eAAe;EACf,yBAAyB;EACzB,mBAAmB;EACnB,uBAAuB;AACzB;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,kCAAkC;AACpC;;AAEA;EACE,kCAAkC;AACpC;;AAEA;EACE,kCAAkC;AACpC;;AAEA;EACE,oCAAoC;AACtC","sourcesContent":[".ant-layout-header {\r\n  font-weight: 500;\r\n  font-size: 18px;\r\n  background-color: #f4f4f6;\r\n  background: #f4f4f6;\r\n  padding: 0px !important;\r\n}\r\n\r\nnz-layout {\r\n  background: #f4f4f6;\r\n}\r\n\r\nnz-header {\r\n  background-color: white !important;\r\n}\r\n\r\n.ant-table-pagination {\r\n  background-color: white !important;\r\n}\r\n\r\n.ant-table-wrapper {\r\n  background-color: white !important;\r\n}\r\n\r\n::ng-deep .ant-table-small {\r\n  border: 1px solid #f0f0f0 !important;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
