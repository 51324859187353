import { Component, Input } from '@angular/core';
import { IModule } from 'src/app/interfaces/IModule';
import { paths } from 'src/component-paths/component-paths';

@Component({
  selector: 'app-laboratory-section',
  templateUrl: './laboratory-section.component.html',
  styleUrls: ['./laboratory-section.component.css'],
})
export class LaboratorySectionComponent {
  @Input() submenu?: IModule[];
  componentPaths = paths;

  constructor() {}

  ngOnInit() {}
}
