import { Component } from '@angular/core';

@Component({
  selector: 'app-imaging-section',
  templateUrl: './imaging-section.component.html',
  styleUrls: ['./imaging-section.component.css']
})
export class ImagingSectionComponent {

}
