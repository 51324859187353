// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.container {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }
  
  .table-container {
    width: 48%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  nz-table {
    width: 100%;
  }
  .ant-table-tbody > tr.submitted-item > td {
    border-left: 2px solid #0d9488;
  }
  ::ng-deep .ant-checkbox-checked ::ng-deep .ant-checkbox-inner {
    background-color: #0d9488 !important;
    border-color: #0d9488 !important;
  }`, "",{"version":3,"sources":["webpack://./src/app/components/ehr/patients/components/complication-section/complication/complication.component.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,8BAA8B;IAC9B,WAAW;EACb;;EAEA;IACE,UAAU;IACV,aAAa;IACb,sBAAsB;IACtB,mBAAmB;EACrB;;EAEA;IACE,WAAW;EACb;EACA;IACE,8BAA8B;EAChC;EACA;IACE,oCAAoC;IACpC,gCAAgC;EAClC","sourcesContent":[".container {\r\n    display: flex;\r\n    justify-content: space-between;\r\n    width: 100%;\r\n  }\r\n  \r\n  .table-container {\r\n    width: 48%;\r\n    display: flex;\r\n    flex-direction: column;\r\n    align-items: center;\r\n  }\r\n  \r\n  nz-table {\r\n    width: 100%;\r\n  }\r\n  .ant-table-tbody > tr.submitted-item > td {\r\n    border-left: 2px solid #0d9488;\r\n  }\r\n  ::ng-deep .ant-checkbox-checked ::ng-deep .ant-checkbox-inner {\r\n    background-color: #0d9488 !important;\r\n    border-color: #0d9488 !important;\r\n  }"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
