import { Component } from '@angular/core';
import { IDateTimeRange } from '../../../interfaces/Dashboard/IDateTimeRange';
import { NzI18nService, en_US } from 'ng-zorro-antd/i18n';
import * as moment from 'moment';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css'],
})
export class DashboardComponent {
  getDate6Months(): Date {
    const currentDate = new Date(); // Get the current date
    const sixMonthsAgo = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth() - 6,
      currentDate.getDate()
    );

    return sixMonthsAgo;
  }
  dateRange?: Date[] = [this.getDate6Months(), new Date()];

  selectedDates: IDateTimeRange = {};

  constructor(private readonly nz: NzI18nService) {
    // TODO - Temporary fix
    // NG Zorro Datepicker doesnt have a greek locale
    // dashboard.component.html:29 [NG-ZORRO]: Missing translations for "DatePicker.lang.rangeWeekPlaceholder" in language "el".
    // You can use "NzI18nService.setLocale" as a temporary fix.
    // Welcome to submit a pull request to help us optimize the translations!
    //
    // If you change locale to greek manually, it will break
    this.nz.setLocale(en_US);
  }

  ngOnInit() {
    this.selectedDates = {
      startDate: this?.dateRange?.at(0)?.toISOString(),
      endDate: this?.dateRange?.at(1)?.toISOString(),
    };
  }

  onDateRangeSelected(dateRange: Date[]): void {
    // Beginning of the day
    const start: Date = dateRange[0];
    start.setHours(0, 0, 0, 0);

    // End of the day
    const end: Date = dateRange[1];
    end.setHours(23, 59, 59, 999);

    this.selectedDates = {
      startDate: moment(start).format() || undefined,
      endDate: moment(end).format() || undefined,
    };
  }

  disabledDate = (current: Date): boolean => {
    // You can implement logic to disable specific dates here
    return current && current.getTime() > Date.now();
  };
}
