import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import {
  IGenericServiceResponse,
  IServiceResponse,
} from '../interfaces/IServiceResponse';
import { IGetList } from '../interfaces/Get/IGetList';
import { IAllergyDetailed } from '../interfaces/Detailed/IAllergyDetailed';
import { environment } from 'src/environments/environment';
import { IAllergyDropdown } from '../interfaces/Dropdown/IAllergyDropdown';
import { IAddAllergy } from '../interfaces/Add/IAddAllergy';
import { EpisodeOfCareService } from './episodeOfCare.service';
import { PatientService } from './patient.service';
import Swal from 'sweetalert2';

@Injectable()
export class AllergyService {
  public allergyModalSub = new BehaviorSubject<boolean>(false);

  public allergyEditSub = new BehaviorSubject<IAllergyDetailed | undefined>(
    undefined
  );

  public allergyDataSub = new BehaviorSubject<IGetList<IAllergyDetailed>>({});

  public allergyLoadingSub = new BehaviorSubject<boolean>(false);

  public allergyNoKnownLoadingSub = new BehaviorSubject<boolean>(false);

  constructor(
    private readonly _http: HttpClient,
    private readonly eocService: EpisodeOfCareService,
    private readonly patientService: PatientService
  ) {}

  async getData() {
    this.allergyLoadingSub.next(true);

    await this.getAllergies(
      this.patientService.patientIDsignal(),
      this.eocService.currentVisitId()
    ).subscribe({
      next: (response) => {
        this.allergyDataSub.next(response?.data!);
        this.allergyLoadingSub.next(false);
        this.allergyNoKnownLoadingSub.next(false);
      },
      error: (err) => {
        Swal.fire({
          text: 'Unable to retrieve allergies!',
          toast: true,
          position: 'bottom-end',
          showCancelButton: false,
          showConfirmButton: false,
          color: 'white',
          background: '#ff6969',
          timer: 3000,
        });
        console.error('Error fetching allergies:', err);
      },
    });
  }

  getAllergies(
    patientId: string,
    visitId?: string
  ): Observable<IGenericServiceResponse<IGetList<IAllergyDetailed>>> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    if (visitId) {
      return this._http.get<
        IGenericServiceResponse<IGetList<IAllergyDetailed>>
      >(`${environment.BACKEND_URL}Allergy/${patientId}?visitId=${visitId}`, {
        headers: headers,
      });
    } else {
      return this._http.get<
        IGenericServiceResponse<IGetList<IAllergyDetailed>>
      >(`${environment.BACKEND_URL}Allergy/${patientId}`, {
        headers: headers,
      });
    }
  }

  getAllergyDropdown(): Observable<IGenericServiceResponse<IAllergyDropdown>> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    return this._http.get<IGenericServiceResponse<IAllergyDropdown>>(
      `${environment.BACKEND_URL}Allergy/DropdownData`,
      {
        headers: headers,
      }
    );
  }

  addAllergy(allergyObj: IAddAllergy) {
    allergyObj.visitId = this.eocService.currentVisitId();
    allergyObj.patientId = this.patientService.patientIDsignal();

    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });

    return this._http.post(`${environment.BACKEND_URL}Allergy`, allergyObj, {
      headers: headers,
    });
  }

  addNoKnownAllergy() {
    const object = {
      patientId: this.patientService.patientIDsignal(),
      visitId: this.eocService.currentVisitId(),
    };

    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });

    return this._http.post(
      `${environment.BACKEND_URL}Allergy/NoKnownAllergies`,
      object,
      {
        headers: headers,
      }
    );
  }

  editAllergy(allergyObj: IAddAllergy) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });

    return this._http.put(
      `${environment.BACKEND_URL}Allergy/${allergyObj.id}`,
      allergyObj,
      {
        headers: headers,
      }
    );
  }

  deleteAllergy(allergyId: string): Observable<IServiceResponse> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });

    return this._http.delete<IServiceResponse>(
      `${environment.BACKEND_URL}Allergy/${allergyId}`,
      {
        headers: headers,
      }
    );
  }

  deleteNoKnownAllergy(allergyId: string): Observable<IServiceResponse> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });

    return this._http.delete<IServiceResponse>(
      `${environment.BACKEND_URL}Allergy/NoKnownAllergies/${allergyId}`,
      {
        headers: headers,
      }
    );
  }
}
