// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.tooltip {
  scale: 1 !important;
  margin-left: 0.2em;
  color: rgba(0, 0, 0, 0.5);
}
.tooltip:hover {
  color: black;
  background-color: #f4f4f6;
  cursor: pointer;
}

::ng-deep .ant-tooltip-inner {
  /* background-color: rgba(0, 0, 0, 0.4); */
  border-radius: 5px !important;
}

::ng-deep .ant-tooltip-arrow {
  display: none;
}
`, "",{"version":3,"sources":["webpack://./src/app/common/tooltip/tooltip.component.css"],"names":[],"mappings":"AAAA;EACE,mBAAmB;EACnB,kBAAkB;EAClB,yBAAyB;AAC3B;AACA;EACE,YAAY;EACZ,yBAAyB;EACzB,eAAe;AACjB;;AAEA;EACE,0CAA0C;EAC1C,6BAA6B;AAC/B;;AAEA;EACE,aAAa;AACf","sourcesContent":[".tooltip {\r\n  scale: 1 !important;\r\n  margin-left: 0.2em;\r\n  color: rgba(0, 0, 0, 0.5);\r\n}\r\n.tooltip:hover {\r\n  color: black;\r\n  background-color: #f4f4f6;\r\n  cursor: pointer;\r\n}\r\n\r\n::ng-deep .ant-tooltip-inner {\r\n  /* background-color: rgba(0, 0, 0, 0.4); */\r\n  border-radius: 5px !important;\r\n}\r\n\r\n::ng-deep .ant-tooltip-arrow {\r\n  display: none;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
