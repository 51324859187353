import { Component } from '@angular/core';
import { KeycloakService } from 'keycloak-angular';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-logout',
  templateUrl: './logout.component.html',
  styleUrls: ['./logout.component.css'],
})
export class LogoutComponent {
  constructor(private readonly keycloak: KeycloakService) {}

  logout() {
    this.keycloak.logout(environment.APP_URL + '/login');
  }

  resetPassword() {
    window.open(
      'https://acc-auth.3ahealth.com/realms/SaaSEHR/login-actions/reset-credentials'
    );
  }
}
