import { Component } from '@angular/core';
import Swal from 'sweetalert2';
import { IGetList } from 'src/app/interfaces/Get/IGetList';
import { Subject, takeUntil } from 'rxjs';
import { IProcedureDetailed } from '../../../../../../interfaces/Procedure/IProcedureDetailed';
import { ProcedureService } from '../../../../../../services/procedure.service';
import { ProcedureAddEditComponent } from './procedure-add-edit/procedure-add-edit.component';
import { ProcedureExpandComponent } from './procedure-expand/procedure-expand.component';

@Component({
  selector: 'app-procedure',
  templateUrl: './procedure.component.html',
  styleUrls: ['./procedure.component.css'],
})
export class ProcedureComponent {
  public addComp = ProcedureAddEditComponent;
  public expandComp = ProcedureExpandComponent;

  private destroy$ = new Subject<void>();

  procedures?: IGetList<IProcedureDetailed>;
  isLoading: boolean = false;
  isNoKnownLoading: boolean = false;

  constructor(private readonly procedureService: ProcedureService) {}

  ngOnInit() {
    this.procedureService.procedureLoadingSub
      .pipe(takeUntil(this.destroy$))
      .subscribe((data: boolean) => {
        this.isLoading = data;
      });

    this.procedureService.procedureLoadingSub
      .pipe(takeUntil(this.destroy$))
      .subscribe((data: boolean) => {
        this.isNoKnownLoading = data;
      });

    this.procedureService.procedureDataSub
      .pipe(takeUntil(this.destroy$))
      .subscribe((data: IGetList<IProcedureDetailed>) => {
        this.procedures = data;
      });

    this.procedureService.getData();
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  openModalFun(item?: IProcedureDetailed) {
    this.procedureService.procedureModalSub.next(true);
    this.procedureService.procedureEditSub.next(item);
  }

  deleteFun(procedureId: number) {
    Swal.fire({
      text: 'Are you sure that you want to delete this procedure?',
      showDenyButton: false,
      showCancelButton: true,
      confirmButtonText: 'Ok',
    }).then((result) => {
      if (result.isConfirmed) {
        this.procedureService
          .deleteProcedure(procedureId.toString())
          .subscribe({
            next: (d) => {
              Swal.fire({
                text: 'Procedure deleted successfully!',
                toast: true,
                position: 'bottom-end',
                showCancelButton: false,
                showConfirmButton: false,
                color: 'white',
                background: '#0d9488',
                timer: 3000,
              });

              this.procedureService.getData();
            },
            error: (err) => {
              Swal.fire({
                text: 'Unable to delete Procedure!',
                toast: true,
                position: 'bottom-end',
                showCancelButton: false,
                showConfirmButton: false,
                color: 'white',
                background: '#ff6969',
                timer: 3000,
              });
              console.log(err);
            },
          });
      } else if (result.isDenied) {
        Swal.fire('Procedure is not deleted', '', 'info');
      }
    });
  }

  handleToggleFun(preview: boolean, switchValue: boolean, unknownid?: string) {
    if (!preview) {
      if (switchValue) {
        this.procedureService.procedureNoKnownLoadingSub.next(true);
        this.procedureService.addNoKnownProcedure().subscribe({
          next: () => {
            Swal.fire({
              text: 'No known Procedures set successfully!',
              toast: true,
              position: 'bottom-end',
              showCancelButton: false,
              showConfirmButton: false,
              color: 'white',
              background: '#0d9488',
              timer: 3000,
            });

            this.procedureService.getData();
          },
          error: (err) => {
            Swal.fire({
              text: 'Unable to set No Known Procedures!',
              toast: true,
              position: 'bottom-end',
              showCancelButton: false,
              showConfirmButton: false,
              color: 'white',
              background: '#ff6969',
              timer: 3000,
            });
            console.error('Error setting No Known Procedures:', err);
          },
        });
      } else {
        if (unknownid) {
          this.procedureService.procedureNoKnownLoadingSub.next(true);
          this.procedureService.deleteNoKnownProcedure(unknownid).subscribe({
            next: () => {
              Swal.fire({
                text: 'No known Procedures unset successfully!',
                toast: true,
                position: 'bottom-end',
                showCancelButton: false,
                showConfirmButton: false,
                color: 'white',
                background: '#0d9488',
                timer: 3000,
              });

              this.procedureService.getData();
            },
            error: (err) => {
              Swal.fire({
                text: 'Unable to unset No Known Procedure!',
                toast: true,
                position: 'bottom-end',
                showCancelButton: false,
                showConfirmButton: false,
                color: 'white',
                background: '#ff6969',
                timer: 3000,
              });
              console.error('Error unsetting No Known Procedure:', err);
            },
          });
        }
      }
    }
  }

  expandSet = new Set<number>();
  onExpandChange(id: number, checked: boolean): void {
    if (checked) {
      this.expandSet.add(id);
    } else {
      this.expandSet.delete(id);
    }
  }
}
