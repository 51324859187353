// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ant-layout-header {
  font-weight: 600;
  font-size: 20px;
  background-color: #f0f2f5;
}

nz-layout {
  background: #f0f2f5;
}

nz-content {
  padding-left: 50px;
  padding-right: 50px;
  flex-direction: column !important;
}

.pd {
  margin-bottom: 1em;
}

.pdd {
  margin-left: 0.5em;
  width: 100% !important;
}

.last-row {
  margin-bottom: 2em !important;
}

::ng-deep
  .ant-select:not(.ant-select-customize-input)
  ::ng-deep
  .ant-select-selector {
  border-radius: 5px;
}

.nt {
  margin-left: 0.5em;
  width: 100%;
  border: 1px solid #d9d9d9;
  border-radius: 5px;
  padding: 0px 11px;
}

.ng-invalid {
  border: 1px solid red;
  border-radius: 5px;
}

.toggle {
  width: auto !important;
}
`, "",{"version":3,"sources":["webpack://./src/app/components/ehr/patients/preview-summary/preview-summary.component.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;EAChB,eAAe;EACf,yBAAyB;AAC3B;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,kBAAkB;EAClB,mBAAmB;EACnB,iCAAiC;AACnC;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,kBAAkB;EAClB,sBAAsB;AACxB;;AAEA;EACE,6BAA6B;AAC/B;;AAEA;;;;EAIE,kBAAkB;AACpB;;AAEA;EACE,kBAAkB;EAClB,WAAW;EACX,yBAAyB;EACzB,kBAAkB;EAClB,iBAAiB;AACnB;;AAEA;EACE,qBAAqB;EACrB,kBAAkB;AACpB;;AAEA;EACE,sBAAsB;AACxB","sourcesContent":[".ant-layout-header {\r\n  font-weight: 600;\r\n  font-size: 20px;\r\n  background-color: #f0f2f5;\r\n}\r\n\r\nnz-layout {\r\n  background: #f0f2f5;\r\n}\r\n\r\nnz-content {\r\n  padding-left: 50px;\r\n  padding-right: 50px;\r\n  flex-direction: column !important;\r\n}\r\n\r\n.pd {\r\n  margin-bottom: 1em;\r\n}\r\n\r\n.pdd {\r\n  margin-left: 0.5em;\r\n  width: 100% !important;\r\n}\r\n\r\n.last-row {\r\n  margin-bottom: 2em !important;\r\n}\r\n\r\n::ng-deep\r\n  .ant-select:not(.ant-select-customize-input)\r\n  ::ng-deep\r\n  .ant-select-selector {\r\n  border-radius: 5px;\r\n}\r\n\r\n.nt {\r\n  margin-left: 0.5em;\r\n  width: 100%;\r\n  border: 1px solid #d9d9d9;\r\n  border-radius: 5px;\r\n  padding: 0px 11px;\r\n}\r\n\r\n.ng-invalid {\r\n  border: 1px solid red;\r\n  border-radius: 5px;\r\n}\r\n\r\n.toggle {\r\n  width: auto !important;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
