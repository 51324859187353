// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ant-layout-header {
  font-weight: 600;
  font-size: 20px;
  background-color: #f4f4f6;
  background: #f4f4f6;
}

nz-layout {
  background: #f4f4f6;
  height: 100%;
}

nz-content {
  padding-left: 50px;
  padding-right: 50px;
}

.section-header {
  margin-bottom: 1em;
}
`, "",{"version":3,"sources":["webpack://./src/app/components/ehr/patients/components/laboratory-section/laboratory-section.component.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;EAChB,eAAe;EACf,yBAAyB;EACzB,mBAAmB;AACrB;;AAEA;EACE,mBAAmB;EACnB,YAAY;AACd;;AAEA;EACE,kBAAkB;EAClB,mBAAmB;AACrB;;AAEA;EACE,kBAAkB;AACpB","sourcesContent":[".ant-layout-header {\r\n  font-weight: 600;\r\n  font-size: 20px;\r\n  background-color: #f4f4f6;\r\n  background: #f4f4f6;\r\n}\r\n\r\nnz-layout {\r\n  background: #f4f4f6;\r\n  height: 100%;\r\n}\r\n\r\nnz-content {\r\n  padding-left: 50px;\r\n  padding-right: 50px;\r\n}\r\n\r\n.section-header {\r\n  margin-bottom: 1em;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
