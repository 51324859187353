// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ant-layout-header {
  font-weight: 500;
  font-size: 18px;
  background-color: #fbfbfb;
  background: #fbfbfb;
  padding: 0px !important;
}

nz-layout {
  background: #fbfbfb;
}

::ng-deep
  .ant-table.ant-table-middle
  .ant-table-tbody
  .ant-table-wrapper:only-child
  .ant-table {
  margin: 0px !important;
}

.open-visit {
  margin-left: 1.5em;
  cursor: pointer;
}

.discard-visit {
  cursor: pointer;
}

::ng-deep
  div:where(.swal2-container)
  button:where(.swal2-styled).swal2-confirm {
  background-color: #0d9488;
}
`, "",{"version":3,"sources":["webpack://./src/app/components/ehr/patients/components/eoc-section/visit-expand/visit-expand.component.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;EAChB,eAAe;EACf,yBAAyB;EACzB,mBAAmB;EACnB,uBAAuB;AACzB;;AAEA;EACE,mBAAmB;AACrB;;AAEA;;;;;EAKE,sBAAsB;AACxB;;AAEA;EACE,kBAAkB;EAClB,eAAe;AACjB;;AAEA;EACE,eAAe;AACjB;;AAEA;;;EAGE,yBAAyB;AAC3B","sourcesContent":[".ant-layout-header {\r\n  font-weight: 500;\r\n  font-size: 18px;\r\n  background-color: #fbfbfb;\r\n  background: #fbfbfb;\r\n  padding: 0px !important;\r\n}\r\n\r\nnz-layout {\r\n  background: #fbfbfb;\r\n}\r\n\r\n::ng-deep\r\n  .ant-table.ant-table-middle\r\n  .ant-table-tbody\r\n  .ant-table-wrapper:only-child\r\n  .ant-table {\r\n  margin: 0px !important;\r\n}\r\n\r\n.open-visit {\r\n  margin-left: 1.5em;\r\n  cursor: pointer;\r\n}\r\n\r\n.discard-visit {\r\n  cursor: pointer;\r\n}\r\n\r\n::ng-deep\r\n  div:where(.swal2-container)\r\n  button:where(.swal2-styled).swal2-confirm {\r\n  background-color: #0d9488;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
