import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { IModule } from 'src/app/interfaces/IModule';
import { IPatientDetailed } from 'src/app/interfaces/Detailed/IPatientDetailed';
import { selectMenu } from 'src/app/selectors/menu.selector';
import { PatientService } from 'src/app/services/patient.service';
import { EpisodeOfCareService } from 'src/app/services/episodeOfCare.service';
import { paths } from 'src/component-paths/component-paths';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.css'],
})
export class ProfileComponent {
  public menuData?: IModule;
  public selectedOption?: number;
  public isCollapsed: boolean = false;

  componentPaths = paths;

  constructor(
    private readonly route: ActivatedRoute,
    private readonly patientService: PatientService,
    private readonly store: Store,
    private readonly eocService: EpisodeOfCareService
  ) {}

  public patientID: string = '';

  public patientInfo?: IPatientDetailed;

  toggleCollapsed(): void {
    this.isCollapsed = !this.isCollapsed;
  }

  updateSelectedOption(option: number) {
    this.selectedOption = option;
  }

  clickItem(m: IModule) {
    if (m.path != this.componentPaths.pilotStudy) {
      this.updateSelectedOption(m.id);
      this.scrollToSection(m.path);
    }
  }

  activeSubmenu(): string {
    return (
      this.menuData?.childModules?.find((d) =>
        d.childModules.find((x) => x.id === this.selectedOption)
      )?.path ||
      this.menuData?.childModules?.find((d) => d.id === this.selectedOption)
        ?.path ||
      ''
    );
  }

  getSubmenu(menu: string): IModule[] | undefined {
    return this.menuData?.childModules?.find((d) => d.path === menu)
      ?.childModules;
  }

  scrollToSection(sectionId: string): void {
    const element = document.getElementById(sectionId);
    if (element) {
      const offset = element.offsetTop - window.innerHeight * 0.08; // Calculate the offset (6vh)
      window.scroll({
        top: offset,
        behavior: 'smooth',
      });
    }
  }

  ngOnInit() {
    // Reset Episode of Care and Visit ID upon rendering
    // the profile component
    this.eocService.currentEocId.set('');
    this.eocService.currentVisitId.set('');

    this.patientService.patientInfoSub.next({});
    this.patientService.patientLoadingSub.next(true);

    this.route.queryParams.subscribe((params) => {
      this.patientID = params['patientID'];
    });

    this.patientService.patientIDsignal.set(this.patientID);

    this.patientService.getPatientByID(this.patientID).subscribe({
      next: (response) => {
        this.patientService.patientInfoSub.next(response?.data!);
        this.patientService.patientLoadingSub.next(false);
        this.patientInfo = response?.data; // Assuming data is the property you want

        // Get profile module
        // Set selected option
        this.store.select(selectMenu).subscribe((data) => {
          this.menuData = data
            .find((d) => d.path == 'allPatients')
            ?.childModules?.find((d) => d.path === 'profile');

          // If not imported
          // Remove IPS preview TAB (ID: 26)
          if (!this.patientInfo?.isImported && this?.menuData?.childModules) {
            this.menuData = {
              ...this.menuData,
              childModules:
                this.menuData?.childModules?.filter((c) => c.id != 26) || [],
            };
          }

          // If not female
          // Remove Pregnancy TAB (ID: 21)
          if (
            this.patientInfo?.gender?.toLowerCase() != 'female' &&
            this?.menuData?.childModules
          ) {
            this.menuData = {
              ...this.menuData,
              childModules:
                this.menuData?.childModules?.filter((c) => c.id != 21) || [],
            };
          }

          // If not inpatient
          // Remove Daily Monitoring TAB (ID: 28)
          // Remove Etiology TAB (ID: 45)
          // Remove Complication TAB (ID: 46)
          // Remove Tracheostomy 2 TAB (ID: 49)
          this.menuData = {
            id: this.menuData?.id!,
            name: this.menuData?.name!,
            description: this.menuData?.description!,
            icon: this.menuData?.icon!,
            path: this.menuData?.path!,
            orderBy: this.menuData?.orderBy!,
            isActive: this.menuData?.isActive!,
            ...this.menuData,
            childModules:
              this.menuData?.childModules?.filter(
                (c) => c.id != 28 && c.id != 45 && c.id != 46
              ) || [],
          };
          // if the first option is pilot study (44)
          // have tracheostomy2 (49) as preselcted
          if (this.menuData?.childModules?.at(0)?.id == 44) {
            this.selectedOption = 49;
          } else if (
            this.menuData?.childModules?.at(0)?.childModules.length == 1
          ) {
            this.selectedOption = this.menuData?.childModules?.[0].id;
          } else {
            this.selectedOption =
              this.menuData?.childModules?.at(0)?.childModules?.at(0)?.id ||
              this.menuData?.childModules?.[0].id ||
              this.menuData?.id ||
              0;
          }
        });
      },
      error: (err) => {
        console.error('Error fetching patient information:', err);
      },
    });
  }
}
