import { Component } from '@angular/core';
import { Subject, takeUntil } from 'rxjs';
import Swal from 'sweetalert2';
import { SocialHistoryAddEditComponent } from './social-history-add-edit/social-history-add-edit.component';
import { ISocialHistoryDetailed } from '../../../../../../interfaces/SocialHistory/ISocialHistoryDetailed';
import { SocialHistoryService } from '../../../../../../services/socialHistory.service';

@Component({
  selector: 'app-social-history',
  templateUrl: './social-history.component.html',
  styleUrls: ['./social-history.component.css'],
})
export class SocialHistoryComponent {
  public addComp = SocialHistoryAddEditComponent;

  private destroy$ = new Subject<void>();

  socialHistory?: ISocialHistoryDetailed[];
  isLoading: boolean = false;

  constructor(private readonly socialHistoryService: SocialHistoryService) {}

  openModalFun(item?: ISocialHistoryDetailed) {
    this.socialHistoryService.socialHistoryModalSub.next(true);
    this.socialHistoryService.socialHistoryEditSub.next(item);
  }

  deleteFun(socialHistoryId: number) {
    Swal.fire({
      text: 'Are you sure that you want to delete this social history?',
      showDenyButton: false,
      showCancelButton: true,
      confirmButtonText: 'Ok',
    }).then((result) => {
      if (result.isConfirmed) {
        this.socialHistoryService
          .deleteSocialHistory(socialHistoryId.toString())
          .subscribe({
            next: (d) => {
              Swal.fire({
                text: 'Social history deleted successfully!',
                toast: true,
                position: 'bottom-end',
                showCancelButton: false,
                showConfirmButton: false,
                color: 'white',
                background: '#0d9488',
                timer: 3000,
              });

              this.socialHistoryService.deleteSocialHistoryFromTable(
                socialHistoryId.toString()
              );
            },
            error: (err) => {
              Swal.fire({
                text: 'Unable to delete social history!',
                toast: true,
                position: 'bottom-end',
                showCancelButton: false,
                showConfirmButton: false,
                color: 'white',
                background: '#ff6969',
                timer: 3000,
              });
              console.log(err);
            },
          });
      } else if (result.isDenied) {
        Swal.fire('Social history is not deleted', '', 'info');
      }
    });
  }
  ngOnInit() {
    this.socialHistoryService.socialHistoryLoadingSub
      .pipe(takeUntil(this.destroy$))
      .subscribe((data: boolean) => {
        this.isLoading = data;
      });

    this.socialHistoryService.socialHistoryDataSub
      .pipe(takeUntil(this.destroy$))
      .subscribe((data: ISocialHistoryDetailed[]) => {
        this.socialHistory = data;
      });

    this.socialHistoryService.getData();
  }
}
