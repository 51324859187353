// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.layout {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.resetPassword {
  background-color: #ffd470;
}

.resetPassword,
.logout {
  display: flex;
  align-items: center;
  width: 100%;
  color: #333333 !important;
}

.resetPassword:hover {
  background-color: #d9d9d9 !important;
}

.logout:hover {
  background-color: #fcd6d6 !important;
}

.mat-icon {
  margin-right: 0.5em;
}

::ng-deep .mat-mdc-menu-panel {
  padding: 10px 10px !important;
}
`, "",{"version":3,"sources":["webpack://./src/app/components/ehr/logout/logout.component.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,SAAS;AACX;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;;EAEE,aAAa;EACb,mBAAmB;EACnB,WAAW;EACX,yBAAyB;AAC3B;;AAEA;EACE,oCAAoC;AACtC;;AAEA;EACE,oCAAoC;AACtC;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,6BAA6B;AAC/B","sourcesContent":[".layout {\r\n  display: flex;\r\n  flex-direction: column;\r\n  gap: 15px;\r\n}\r\n\r\n.resetPassword {\r\n  background-color: #ffd470;\r\n}\r\n\r\n.resetPassword,\r\n.logout {\r\n  display: flex;\r\n  align-items: center;\r\n  width: 100%;\r\n  color: #333333 !important;\r\n}\r\n\r\n.resetPassword:hover {\r\n  background-color: #d9d9d9 !important;\r\n}\r\n\r\n.logout:hover {\r\n  background-color: #fcd6d6 !important;\r\n}\r\n\r\n.mat-icon {\r\n  margin-right: 0.5em;\r\n}\r\n\r\n::ng-deep .mat-mdc-menu-panel {\r\n  padding: 10px 10px !important;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
