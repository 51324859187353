import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { Observable } from 'rxjs';
import { IGenericServiceResponse } from '../interfaces/IServiceResponse';
import { IPatientsDropdown } from '../interfaces/IPatientsDropdown';
import { IAddPatient } from '../interfaces/Add/IAddPatient';
import {IAddImaging} from "../interfaces/Imaging/IAddImaging";
import {IAddPatientProfile} from "../interfaces/IAddPatientProfile";
import {IRegisterPatient} from "../interfaces/IRegisterPatient";

@Injectable()
export class AddPatientService {
  constructor(private _http: HttpClient) {}

  private profilePicture!: Blob | null;


  getPatientsDropDown(): Observable<
    IGenericServiceResponse<IPatientsDropdown>
  > {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });

    return this._http.get<IGenericServiceResponse<IPatientsDropdown>>(
      `${environment.BACKEND_URL}Patient/DropdownData`,
      {
        headers: headers,
      }
    );
  }

  savePatient(paramsObj: IAddPatient) : Observable<IGenericServiceResponse<IRegisterPatient>> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });

    return this._http.post<IGenericServiceResponse<IRegisterPatient>>(`${environment.BACKEND_URL}Patient`, paramsObj, {
      headers: headers,
    });
  }

  uploadProfilePicture(formData: FormData) : Observable<any> {
    return this._http.post(`${environment.BACKEND_URL}Patient/UploadProfilePicture`, formData);
  }

  getProfilePicture(): Blob | null {
    return this.profilePicture;
  }

  setProfilePicture(imageData: Blob | null) {
    this.profilePicture = imageData;
  }
}
