import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, WritableSignal, signal } from '@angular/core';
import { environment } from '../../environments/environment';
import { ICreateEpisodeOfCare } from '../interfaces/ICreateEpisodeOfCare';
import {
  IGenericServiceResponse,
  IServiceResponse,
} from '../interfaces/IServiceResponse';
import {
  BehaviorSubject,
  Observable,
  catchError,
  from,
  mergeMap,
  throwError,
} from 'rxjs';
import { IGetEpisodesOfCare } from '../interfaces/IGetEpisodesOfCare';
import { IPreviewDraft } from '../interfaces/Detailed/IPreviewDraftDetailed';
import { ICoding } from '../interfaces/ICoding';
import { PatientService } from './patient.service';
import Swal from 'sweetalert2';

@Injectable()
export class EpisodeOfCareService {
  public currentEocId = signal('');
  public currentVisitId = signal('');

  public eocDataSub = new BehaviorSubject<IGetEpisodesOfCare[]>([]);

  public isLoadingSub = new BehaviorSubject<boolean>(false);

  public previewValidSub = new BehaviorSubject<boolean>(false);

  public eocEndedSub = new BehaviorSubject<boolean>(false);

  public potentialDiagnosisSub = new BehaviorSubject<number | undefined>(
    undefined
  );

  public noteSub = new BehaviorSubject<string>('');

  constructor(
    private _http: HttpClient,
    private readonly patientService: PatientService
  ) {}

  setPD(pdId: number) {
    this.potentialDiagnosisSub.next(pdId);
  }

  unsetPd() {
    this.potentialDiagnosisSub.next(undefined);
  }

  setNote(note: string) {
    this.noteSub.next(note);
  }

  unsetNote() {
    this.noteSub.next('');
  }

  validatePreview() {
    this.previewValidSub.next(true);
  }

  unvalidatePreview() {
    this.previewValidSub.next(false);
  }

  setEnded(ended: boolean) {
    this.eocEndedSub.next(ended);
  }

  eocEnded() {
    this.eocEndedSub.next(true);
  }

  eocNotEnded() {
    this.eocEndedSub.next(false);
  }

  async getData() {
    this.isLoadingSub.next(true);
    this.getEpisodesOfCare(this.patientService.patientIDsignal()).subscribe({
      next: (d) => {
        this.eocDataSub.next(d?.data);
        this.isLoadingSub.next(false);
      },
      error: (err) => {
        Swal.fire({
          text: 'Unable to retrieve Episodes of Care!',
          toast: true,
          position: 'bottom-end',
          showCancelButton: false,
          showConfirmButton: false,
          color: 'white',
          background: '#ff6969',
          timer: 3000,
        });
        console.error('Error fetching  Episodes of Care:', err);
      },
    });
  }

  // UI ONLY - REMOVE FROM OBJECT
  // IN ORDER NOT TO CALL THE API OVER AGAIN
  deleteVisitFromTable(visitId?: string) {
    this.isLoadingSub.next(true);

    // IF ONLY ONE VISIT LEFT FOR THE EOC - REMOVE THE EOC
    this.eocDataSub.next(
      this.eocDataSub.value.filter(
        (eoc) =>
          !(
            eoc.visits?.length == 1 &&
            eoc.visits?.some((visit) => visit.id == visitId)
          )
      )
    );

    // REMOVE THE VISIT
    this.eocDataSub.next(
      this.eocDataSub.value.map((eoc) => ({
        ...eoc,
        visits: eoc.visits?.filter((visit) => visit.id != visitId),
      }))
    );

    this.isLoadingSub.next(false);
  }

  createEpisodeOfCare(
    patientId: string
  ): Observable<IGenericServiceResponse<ICreateEpisodeOfCare>> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });

    return this._http.post<IGenericServiceResponse<ICreateEpisodeOfCare>>(
      `${environment.BACKEND_URL}EpisodeOfCare`,
      { patientId: patientId },
      {
        headers: headers,
      }
    );
  }

  createInpatient(
    patientId: string,
    bedId: string,
    reasonId: string,
    note: string
  ): Observable<IGenericServiceResponse<ICreateEpisodeOfCare>> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });

    return this._http.post<IGenericServiceResponse<ICreateEpisodeOfCare>>(
      `${environment.BACKEND_URL}EpisodeOfCare/Inpatient`,
      {
        patientId: patientId,
        bedId: bedId,
        admissionReasonId: reasonId,
        admissionNotes: note,
      },
      {
        headers: headers,
      }
    );
  }

  getEpisodesOfCare(
    patientId?: string
  ): Observable<IGenericServiceResponse<IGetEpisodesOfCare[]>> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    return this._http.get<IGenericServiceResponse<IGetEpisodesOfCare[]>>(
      `${environment.BACKEND_URL}EpisodeOfCare/${patientId}`,
      {
        headers: headers,
      }
    );
  }

  getPotentialDiagnosis(): Observable<IGenericServiceResponse<ICoding[]>> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    return this._http.get<IGenericServiceResponse<ICoding[]>>(
      `${environment.BACKEND_URL}EpisodeOfCare/DropdownData`,
      {
        headers: headers,
      }
    );
  }

  getPreviewDraft(
    visitId: string
  ): Observable<IGenericServiceResponse<IPreviewDraft>> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    return this._http.get<IGenericServiceResponse<IPreviewDraft>>(
      `${environment.BACKEND_URL}EpisodeOfCare/PreviewDraft/${visitId}`,
      {
        headers: headers,
      }
    );
  }

  deleteVisit(visitId: string): Observable<IServiceResponse> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    return this._http.delete<IServiceResponse>(
      `${environment.BACKEND_URL}EpisodeOfCare/${visitId}`,
      {
        headers: headers,
      }
    );
  }

  createNewVisit(
    eocId: number
  ): Observable<IGenericServiceResponse<ICreateEpisodeOfCare>> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });

    return this._http.post<IGenericServiceResponse<ICreateEpisodeOfCare>>(
      `${environment.BACKEND_URL}EpisodeOfCare/NewVisit/${eocId}`,
      {
        headers: headers,
      }
    );
  }

  // Observable<any>
  saveVisit(): Observable<any> {
    const visitId = this.currentVisitId();

    const requestBody = {
      visitId: visitId,
      patientId: this.patientService.patientIDsignal(),
      eocIsEnded: this.eocEndedSub.value,
      potentialDiagnosisId: this.potentialDiagnosisSub.value,
      notes: this.noteSub.value,
    };

    return this._http
      .put(`${environment.BACKEND_URL}EpisodeOfCare`, requestBody)
      .pipe(
        catchError((err) => {
          console.error(err.error);
          return throwError(() => err.error);
        })
      );
  }
}
