// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.content {
  padding: 20px 100px;
  height: calc(100vh - 66px);
}

.body {
  padding: 20px 50px;
}

nz-header {
  background-color: #f0f2f5 !important;
}

.mb-1 {
  margin-bottom: 1em;
}

.bold {
  font-weight: 500;
}

.import {
  padding-left: 1em;
}

::ng-deep .ant-btn[disabled] {
  cursor: not-allowed;
  background-color: lightgray !important;
}
`, "",{"version":3,"sources":["webpack://./src/app/components/ehr/import/import.component.css"],"names":[],"mappings":"AAAA;EACE,mBAAmB;EACnB,0BAA0B;AAC5B;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,oCAAoC;AACtC;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,iBAAiB;AACnB;;AAEA;EACE,mBAAmB;EACnB,sCAAsC;AACxC","sourcesContent":[".content {\r\n  padding: 20px 100px;\r\n  height: calc(100vh - 66px);\r\n}\r\n\r\n.body {\r\n  padding: 20px 50px;\r\n}\r\n\r\nnz-header {\r\n  background-color: #f0f2f5 !important;\r\n}\r\n\r\n.mb-1 {\r\n  margin-bottom: 1em;\r\n}\r\n\r\n.bold {\r\n  font-weight: 500;\r\n}\r\n\r\n.import {\r\n  padding-left: 1em;\r\n}\r\n\r\n::ng-deep .ant-btn[disabled] {\r\n  cursor: not-allowed;\r\n  background-color: lightgray !important;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
