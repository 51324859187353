import {Component, Input} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ICoding} from 'src/app/interfaces/ICoding';
import {IDoctor} from '../../../../interfaces/IDoctor';
import {NzSelectOptionInterface} from 'ng-zorro-antd/select';
import {MatDialog} from '@angular/material/dialog';
import {ImageCropperComponent} from '../image-cropper/image-cropper.component';
import {AddPatientService} from "../../../../services/addpatient.service";

@Component({
  selector: 'app-personal-info',
  templateUrl: './personal-info.component.html',
  styleUrls: ['./personal-info.component.css'],
})
export class PersonalInfoComponent {
  @Input() formSubmitted?: boolean = false;

  @Input() personalInfoForm: FormGroup = new FormGroup({});

  @Input() gendersList: ICoding[] = [];
  @Input() bloodTypesList: ICoding[] = [];
  @Input() doctorList: IDoctor[] = [];

  @Input() doctors: NzSelectOptionInterface[] = [];

  @Input() profilePicture? : File ;

  // Form fields
  public firstName?: string;
  public lastName?: string;
  public dateOfBirth?: Date;
  public phone?: string;
  public email?: string;
  public gender?: string = '';
  public bloodType?: string = '';
  public height?: number;
  public weight?: number;
  public doctor?: string;

  avatarImageUrl?: string;

  constructor(private readonly formBuilder: FormBuilder,private dialog: MatDialog,private addPatientService: AddPatientService) {}

  ngOnInit() {
    this.personalInfoForm.addControl(
      'firstName',
      this.formBuilder.control('', Validators.required)
    );
    this.personalInfoForm.addControl(
      'lastName',
      this.formBuilder.control('', Validators.required)
    );
    this.personalInfoForm.addControl(
      'dateOfBirth',
      this.formBuilder.control('', Validators.required)
    );
    this.personalInfoForm.addControl(
      'phone',
      this.formBuilder.control('', Validators.required)
    );
    this.personalInfoForm.addControl(
      'email',
      this.formBuilder.control('', [Validators.required, Validators.email])
    );
    this.personalInfoForm.addControl(
      'gender',
      this.formBuilder.control('', Validators.required)
    );
    this.personalInfoForm.addControl('bloodType', this.formBuilder.control(''));
    this.personalInfoForm.addControl('height', this.formBuilder.control(''));
    this.personalInfoForm.addControl('weight', this.formBuilder.control(''));
    this.personalInfoForm.addControl('doctor', this.formBuilder.control(''));
  }

  getCurrentDate() {
    return new Date().toISOString().split('T')[0];
  }

  uploadAndOpenImageCropper(): void {
    const fileInput = document.createElement('input');
    fileInput.type = 'file';
    fileInput.accept = 'image/*';
    fileInput.addEventListener('change', (event: any) => {
      const file = event.target.files[0];
      const reader = new FileReader();
      reader.onload = () => {
        this.dialog.open(ImageCropperComponent, {
          width: '600px',
          data: { imageChangedEvent: { target: { files: [file] } } }
        });

        this.dialog.afterAllClosed.subscribe({
          next: () => {
            if (this.addPatientService.getProfilePicture() != null) {
              const UrlFromBlob = this.addPatientService.getProfilePicture();
              this.avatarImageUrl = URL.createObjectURL(UrlFromBlob!);
            } else {
              this.avatarImageUrl = undefined;
            }
          }
        })
      };
      reader.readAsDataURL(file);
    })
    fileInput.click();
  }


}
