import { Component, Input } from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  ValidationErrors,
  ValidatorFn,
} from '@angular/forms';
import { ICoding } from 'src/app/interfaces/ICoding';

@Component({
  selector: 'app-identifiers',
  templateUrl: './identifiers.component.html',
  styleUrls: ['./identifiers.component.css'],
})
export class IdentifiersComponent {
  @Input() formSubmitted?: boolean = false;

  @Input() identifiersForm: FormGroup = new FormGroup({});

  @Input() countriesList: ICoding[] = [];

  public nationalId?: string = '';
  public nationalIdCountry?: string = '';
  public passport?: string = '';
  public passportCountry?: string = '';
  public arc?: string = '';

  constructor(private readonly formBuilder: FormBuilder) {}

  private customValidator(): ValidatorFn {
    return (): ValidationErrors | null => {
      const nationalId = this.identifiersForm.get('nationalId')?.value;
      const nationalIdCountry =
        this.identifiersForm.get('nationalIdCountry')?.value;
      const passport = this.identifiersForm.get('passport')?.value;
      const passportCountry =
        this.identifiersForm.get('passportCountry')?.value;
      const arc = this.identifiersForm.get('arc')?.value;

      if (
        this.identifiersForm.controls['nationalId'] &&
        this.identifiersForm.controls['nationalIdCountry'] &&
        this.identifiersForm.controls['passport'] &&
        this.identifiersForm.controls['passportCountry'] &&
        this.identifiersForm.controls['arc']
      ) {
        const atLeastOneFilled =
          (nationalId.length && nationalIdCountry) ||
          (passport && passportCountry) ||
          arc;

        const nationalValid =
          (nationalId && nationalIdCountry) ||
          (!nationalId && !nationalIdCountry);

        const passportValid =
          (passport && passportCountry) || (!passport && !passportCountry);

        // Set or clear errors based on the condition
        if (!atLeastOneFilled) {
          this.identifiersForm.setErrors({ identifierMissing: true });
          this.identifiersForm
            .get('nationalId')
            ?.setErrors({ incorrect: true });
          this.identifiersForm
            .get('nationalIdCountry')
            ?.setErrors({ incorrect: true });
          this.identifiersForm.get('passport')?.setErrors({ incorrect: true });
          this.identifiersForm
            .get('passportCountry')
            ?.setErrors({ incorrect: true });
          this.identifiersForm.get('arc')?.setErrors({ incorrect: true });
        } else {
          this.identifiersForm.setErrors(null);
          this.identifiersForm.get('nationalId')?.setErrors(null);
          this.identifiersForm.get('nationalIdCountry')?.setErrors(null);
          this.identifiersForm.get('passport')?.setErrors(null);
          this.identifiersForm.get('passportCountry')?.setErrors(null);
          this.identifiersForm.get('arc')?.setErrors(null);
        }

        if (!nationalValid) {
          this.identifiersForm.setErrors({ nationalIdIncomplete: true });
          this.identifiersForm
            .get('nationalId')
            ?.setErrors({ incorrect: true });
          this.identifiersForm
            .get('nationalIdCountry')
            ?.setErrors({ incorrect: true });
        }

        if (!passportValid) {
          this.identifiersForm.setErrors({ passportIncomplete: true });
          this.identifiersForm.get('passport')?.setErrors({ incorrect: true });
          this.identifiersForm
            .get('passportCountry')
            ?.setErrors({ incorrect: true });
        }
      }
      return null; // Validation passes
    };
  }

  ngOnInit() {
    this.identifiersForm.addControl('nationalId', this.formBuilder.control(''));
    this.identifiersForm.addControl(
      'nationalIdCountry',
      this.formBuilder.control('')
    );
    this.identifiersForm.addControl('passport', this.formBuilder.control(''));
    this.identifiersForm.addControl(
      'passportCountry',
      this.formBuilder.control('')
    );
    this.identifiersForm.addControl('arc', this.formBuilder.control(''));
    this.identifiersForm.setValidators(this.customValidator());
    this.identifiersForm.updateValueAndValidity();
  }
}
