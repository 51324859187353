import { Component, Input, SimpleChanges } from '@angular/core';
import { Chart } from 'chart.js/auto';
import { IDashboardChart } from '../../../../interfaces/Dashboard/IDashboardChart';
import { HttpClient } from '@angular/common/http';
import { IGenericServiceResponse } from '../../../../interfaces/IServiceResponse';
import { environment } from '../../../../../environments/environment';
import { IDateTimeRange } from 'src/app/interfaces/Dashboard/IDateTimeRange';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-dashboard-discharge-types',
  templateUrl: './dashboard-discharge-types.component.html',
  styleUrls: ['./dashboard-discharge-types.component.css'],
})
export class DashboardDischargeTypesComponent {
  @Input() dateTimeRange: IDateTimeRange | null = null; // Input to receive the selected date range

  constructor(private http: HttpClient,private translate: TranslateService) {}

  public DischargeTypeData: IDashboardChart | null = null;

  ngAfterViewInit(): void {
    this.fetchData();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['dateTimeRange'] && !changes['dateTimeRange'].firstChange) {
      this.fetchData(); // Fetch data whenever dateTimeRange changes
    }
  }

  BarChart(): void {
    const labels = this.DischargeTypeData?.labels || [];
    const datasets = this.DischargeTypeData?.dataSet || [];

    const data = {
      labels: labels,
      datasets: datasets.map((dataset, index) => ({
        label: dataset.label,
        data: dataset.data,
        backgroundColor: [dataset.backgroundColor],
        borderColor: 'rgba(128, 128, 128, 0.6)', // Gray border color
        borderRadius: 10, // Set the border radius to make the chart rounded
        borderWidth: 1,
      })),
    };

    new Chart('dischargeType', {
      type: 'bar',
      data: data,
      options: {
        indexAxis: 'x',
        scales: {
          x: {
            ticks: {
              stepSize: 1, // Set step size to 1 to display whole numbers
            },
          },
        },
        elements: {
          bar: {
            borderWidth: 1,
          },
        },
        responsive: true,
        plugins: {
          legend: {
            position: 'top',
          },
          title: {
            display: true,
            text: this.translate.instant('Discharge Types'),
          },
        },
      },
    });
  }

  fetchData(): void {
    let chartStatus = Chart.getChart('dischargeType');
    if (chartStatus != undefined) {
      chartStatus.destroy();
    }

    this.http
      .post<IGenericServiceResponse<IDashboardChart>>(
        `${environment.BACKEND_URL}Dashboard/DischargeType`,
        this.dateTimeRange
      )
      .subscribe((data) => {
        this.DischargeTypeData = data.data;
        this.BarChart();
      });
  }
}
