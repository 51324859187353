import { Component } from '@angular/core';
import { Subject, takeUntil } from 'rxjs';
import Swal from 'sweetalert2';
import { IVaccinationDetailed } from '../../../../../../interfaces/Vaccination/IVaccinationDetailed';
import { VaccinationService } from '../../../../../../services/vaccination.service';
import { VaccinationAddEditComponent } from './vaccination-add-edit/vaccination-add-edit.component';
import { AllergiesExpandComponent } from '../../alerts-section/allergies/allergies-expand/allergies-expand.component';
import { VaccinationExpandComponent } from './vaccination-expand/vaccination-expand.component';
import { IGetList } from 'src/app/interfaces/Get/IGetList';

@Component({
  selector: 'app-vaccination',
  templateUrl: './vaccination.component.html',
  styleUrls: ['./vaccination.component.css'],
})
export class VaccinationComponent {
  protected readonly AllergiesExpandComponent = AllergiesExpandComponent;
  public addComp = VaccinationAddEditComponent;
  public expandComp = VaccinationExpandComponent;

  private destroy$ = new Subject<void>();

  vaccinations?: IGetList<IVaccinationDetailed>;
  isLoading: boolean = false;
  isNoKnownLoading: boolean = false;

  constructor(private readonly vaccinationService: VaccinationService) {}

  ngOnInit() {
    this.vaccinationService.vaccinationLoadingSub
      .pipe(takeUntil(this.destroy$))
      .subscribe((data: boolean) => {
        this.isLoading = data;
      });

    this.vaccinationService.noKnownLoadingSub
      .pipe(takeUntil(this.destroy$))
      .subscribe((data: boolean) => {
        this.isNoKnownLoading = data;
      });

    this.vaccinationService.vaccinationDataSub
      .pipe(takeUntil(this.destroy$))
      .subscribe((data: IGetList<IVaccinationDetailed>) => {
        this.vaccinations = data;
      });

    this.vaccinationService.getData();
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  openModalFun(item?: IVaccinationDetailed) {
    this.vaccinationService.vaccinationModalSub.next(true);
    this.vaccinationService.vaccinationEditSub.next(item);
  }

  deleteFun(vaccinationId: number) {
    Swal.fire({
      text: 'Are you sure that you want to delete this vaccination?',
      showDenyButton: false,
      showCancelButton: true,
      confirmButtonText: 'Ok',
    }).then((result) => {
      if (result.isConfirmed) {
        this.vaccinationService
          .deleteVaccination(vaccinationId.toString())
          .subscribe({
            next: (d) => {
              Swal.fire({
                text: 'Vaccination deleted successfully!',
                toast: true,
                position: 'bottom-end',
                showCancelButton: false,
                showConfirmButton: false,
                color: 'white',
                background: '#0d9488',
                timer: 3000,
              });

              this.vaccinationService.getData();
            },
            error: (err) => {
              Swal.fire({
                text: 'Unable to delete Vaccination!',
                toast: true,
                position: 'bottom-end',
                showCancelButton: false,
                showConfirmButton: false,
                color: 'white',
                background: '#ff6969',
                timer: 3000,
              });
              console.log(err);
            },
          });
      } else if (result.isDenied) {
        Swal.fire('Vaccination is not deleted', '', 'info');
      }
    });
  }

  handleToggleFun(preview: boolean, switchValue: boolean, unknownid?: string) {
    if (!preview) {
      if (switchValue) {
        this.vaccinationService.noKnownLoadingSub.next(true);
        this.vaccinationService.addNoKnownVaccination().subscribe({
          next: () => {
            Swal.fire({
              text: 'No known Vaccination set successfully!',
              toast: true,
              position: 'bottom-end',
              showCancelButton: false,
              showConfirmButton: false,
              color: 'white',
              background: '#0d9488',
              timer: 3000,
            });

            this.vaccinationService.getData();
          },
          error: (err) => {
            Swal.fire({
              text: 'Unable to set No Known Vaccination!',
              toast: true,
              position: 'bottom-end',
              showCancelButton: false,
              showConfirmButton: false,
              color: 'white',
              background: '#ff6969',
              timer: 3000,
            });
            console.error('Error setting No Known Vaccination:', err);
          },
        });
      } else {
        if (unknownid) {
          this.vaccinationService.noKnownLoadingSub.next(true);
          this.vaccinationService
            .deleteNoKnownVaccination(unknownid)
            .subscribe({
              next: () => {
                Swal.fire({
                  text: 'No known Vaccination unset successfully!',
                  toast: true,
                  position: 'bottom-end',
                  showCancelButton: false,
                  showConfirmButton: false,
                  color: 'white',
                  background: '#0d9488',
                  timer: 3000,
                });

                this.vaccinationService.getData();
              },
              error: (err) => {
                Swal.fire({
                  text: 'Unable to unset No Known Vaccination!',
                  toast: true,
                  position: 'bottom-end',
                  showCancelButton: false,
                  showConfirmButton: false,
                  color: 'white',
                  background: '#ff6969',
                  timer: 3000,
                });
                console.error('Error unsetting No Known Vaccination:', err);
              },
            });
        }
      }
    }
  }
}
