import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import {
  IGenericServiceResponse,
  IGenericServiceResponseTemp,
  IServiceResponse,
} from '../interfaces/IServiceResponse';
import { environment } from 'src/environments/environment';
import { EpisodeOfCareService } from './episodeOfCare.service';
import { PatientService } from './patient.service';
import Swal from 'sweetalert2';
import { IAddImaging } from '../interfaces/Imaging/IAddImaging';
import { IImagingDropdown } from '../interfaces/Imaging/IImagingDropdown';
import { IImagingDetailed } from '../interfaces/Imaging/IImagingDetailed';
import { saveAs } from 'file-saver-es';
import { ICDADetailed } from '../interfaces/CDA/ICDADetailed';
import { NzUploadFile } from 'ng-zorro-antd/upload';

@Injectable()
export class CDAService {
  constructor(
    private readonly _http: HttpClient,
    private readonly eocService: EpisodeOfCareService,
    private readonly patientService: PatientService
  ) {}

  getCDA(
    patientId: string
  ): Observable<IGenericServiceResponseTemp<ICDADetailed>> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    return this._http.get<IGenericServiceResponseTemp<ICDADetailed>>(
      `${environment.BACKEND_URL}Cda/${patientId}`,
      {
        headers: headers,
      }
    );
  }

  uploadXMLfile(file: File): Observable<IServiceResponse> {
    const formData = new FormData();
    formData.append('file', file, file.name);
    formData.append('description', 'aaa');

    return this._http.post<IServiceResponse>(
      `${environment.BACKEND_URL}Cda/UploadXML`,
      formData
    );
  }

  uploadXMLlink(link: string): Observable<IServiceResponse> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    return this._http.post<IServiceResponse>(
      `${environment.BACKEND_URL}Cda/UploadXMLByLink/${link}`,
      null,
      {
        headers: headers,
      }
    );
  }
}
