import { Component, Input } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { ICoding } from 'src/app/interfaces/ICoding';

@Component({
  selector: 'app-insurance',
  templateUrl: './insurance.component.html',
  styleUrls: ['./insurance.component.css'],
})
export class InsuranceComponent {
  @Input() formSubmitted?: boolean = false;

  @Input() insuranceForm: FormGroup = new FormGroup({});

  // @Input() insuranceList: ICoding[] = [
  //   {
  //     name: 'IC1',
  //     id: 1,
  //   },
  //   {
  //     name: 'IC2',
  //     id: 2,
  //   },
  //   {
  //     name: 'IC3',
  //     id: 3,
  //   },
  // ];

  public insurance: string = '';
  //public insuranceCompany: string = '';

  constructor(private formBuilder: FormBuilder) {}

  ngOnInit() {
    this.insuranceForm.addControl(
      'insurance',
      this.formBuilder.control('', Validators.required)
    );

    this.insuranceForm = this.formBuilder.group({
      insurance: ['option1'] // Set the default value here to auto-select 'option1'
    });
    // this.insuranceForm.addControl(
    //   'insuranceCompany',
    //   this.formBuilder.control('', Validators.required)
    // );
  }
}
