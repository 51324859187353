import { BooleanInput } from '@angular/cdk/coercion';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { NavigationExtras, Router } from '@angular/router';
import { IPatientDetailed } from 'src/app/interfaces/Detailed/IPatientDetailed';
import { EpisodeOfCareService } from 'src/app/services/episodeOfCare.service';
import { PatientService } from 'src/app/services/patient.service';
import Swal from 'sweetalert2';
import { DetailedProfileComponent } from '../detailed-profile/detailed-profile.component';
import { MatDialog } from '@angular/material/dialog';
import { Subject, take, takeUntil } from 'rxjs';

@Component({
  selector: 'app-patients-header',
  templateUrl: './patients-header.component.html',
  styleUrls: ['./patients-header.component.css'],
})
export class PatientsHeaderComponent {
  @Input() episodeOfCare: boolean = false;
  @Input() isPreview: boolean = false;
  @Output() isPreviewChange = new EventEmitter<boolean>();

  public patientInfo?: IPatientDetailed;

  public patientLoading?: boolean = false;

  private destroy$ = new Subject<void>();

  public isEOCloading: BooleanInput = false;

  public previewValid: Boolean = false;
  public notes: string = '';
  public potentialDiagnosis?: number;

  public avatarImageUrl?: any;

  constructor(
    private readonly router: Router,
    private readonly eocService: EpisodeOfCareService,
    private readonly patientService: PatientService,
    private dialog: MatDialog
  ) {}

  ngOnInit() {
    this.eocService.previewValidSub
      .pipe(takeUntil(this.destroy$))
      .subscribe((data: Boolean) => {
        this.previewValid = data;
      });

    this.eocService.noteSub
      .pipe(takeUntil(this.destroy$))
      .subscribe((data: string) => {
        this.notes = data;
      });

    this.eocService.potentialDiagnosisSub
      .pipe(takeUntil(this.destroy$))
      .subscribe((data?: number) => {
        this.potentialDiagnosis = data;
      });

    this.patientService.patientLoadingSub
      .pipe(takeUntil(this.destroy$))
      .subscribe((data?: boolean) => {
        this.patientLoading = data;
      });

    this.patientService.patientInfoSub
      .pipe(takeUntil(this.destroy$))
      .subscribe((data?: IPatientDetailed) => {
        this.avatarImageUrl=undefined;
        this.patientInfo = data;

        if (this.patientInfo && this.patientInfo.id) {
          if (Object.keys(this.patientInfo).length !== 0) {
            this.patientService
              .getProfilePicture(this.patientInfo.id.toString())
              .subscribe({
                next: (blob: Blob) => {
                  const reader = new FileReader();
                  reader.onload = () => {
                    this.avatarImageUrl = reader.result;
                  };
                  reader.readAsDataURL(blob);
                },
                error: (error) => {
                  this.avatarImageUrl=undefined;
                  console.error('Error fetching profile picture:', error);
                },
              });
          }
        }
      });
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  calculatePatientBMI(
    weight: number | undefined,
    height: number | undefined
  ): string {
    if (weight && height && height !== 0) {
      const bmi = (weight / Math.pow(height / 100, 2)).toFixed(2);
      return bmi.toString();
    } else {
      return 'N/A';
    }
  }
  goToPatients() {
    this.router.navigate(['/ehr/allPatients']);
  }

  loadPreview() {
    this.isPreview = true;
    this.isPreviewChange.emit(true);
  }

  unloadPreview() {
    this.isPreview = false;
    this.isPreviewChange.emit(false);
  }

  isEOC() {
    return (
      !(
        this.eocService.currentEocId() === null ||
        this.eocService.currentEocId() === ''
      ) && this.router.url.includes('episodeOfCare')
    );
  }

  isInpatientView() {
    return (
      !(
        this.eocService.currentEocId() === null ||
        this.eocService.currentEocId() === ''
      ) && this.router.url.includes('inPatientView')
    );
  }

  goToProfile() {
    Swal.fire({
      text: "Are you sure that you want to view the patient's profile?\nThis visit will remain as draft.",
      showDenyButton: false,
      showCancelButton: true,
      confirmButtonText: 'Ok',
    }).then((result) => {
      if (result.isConfirmed) {
        const navigationExtras: NavigationExtras = {
          queryParams: {
            patientID: this.patientInfo?.id,
          },
        };
        this.router.navigate(['/ehr/profile'], navigationExtras);
        Swal.fire({
          text: 'Visit saved as draft!',
          toast: true,
          position: 'bottom-end',
          showCancelButton: false,
          showConfirmButton: false,
          color: 'white',
          background: '#ffd470',
          timer: 3000,
        });
      } else if (result.isDenied) {
        Swal.fire('Action canceled', '', 'info');
      }
    });
  }

  getPatientDetails() {
    const dialogRef = this.dialog.open(DetailedProfileComponent, {
      width: 'auto',
      height: 'auto',
      data: { patient: this.patientInfo },
    });

    dialogRef.afterClosed().subscribe((result) => {
      console.log('The dialog was closed');
    });
  }

  addEpisodeOfCare() {
    Swal.fire({
      text: 'Are you sure that you want to create a new Episode of Care?',
      showDenyButton: false,
      showCancelButton: true,
      confirmButtonText: 'Ok',
    }).then((result) => {
      if (result.isConfirmed) {
        this.isEOCloading = true;
        this.patientInfo?.id?.toString() &&
          this.eocService
            .createEpisodeOfCare(this.patientInfo?.id?.toString())
            .subscribe({
              next: (res) => {
                res?.data?.episodeOfCareId?.toString() &&
                  this.eocService.currentEocId.set(
                    res?.data?.episodeOfCareId?.toString()
                  );

                res?.data?.visitId?.toString() &&
                  this.eocService.currentVisitId.set(
                    res?.data?.visitId?.toString()
                  );

                this.isEOCloading = false;

                const navigationExtras: NavigationExtras = {
                  queryParams: {
                    patientID: this.patientInfo?.id,
                  },
                };

                this.router.navigate(['/ehr/episodeOfCare'], navigationExtras);

                Swal.fire({
                  text: 'Episode of Care created successfully!',
                  toast: true,
                  position: 'bottom-end',
                  showCancelButton: false,
                  showConfirmButton: false,
                  color: 'white',
                  background: '#0d9488',
                  timer: 3000,
                });
              },
              error: (err) => {
                Swal.fire({
                  text: 'Unable to create Episode of Care!',
                  toast: true,
                  position: 'bottom-end',
                  showCancelButton: false,
                  showConfirmButton: false,
                  color: 'white',
                  background: '#ff6969',
                  timer: 3000,
                });
                console.log('error: ', err);
              },
            });
      } else if (result.isDenied) {
        Swal.fire('Episode of Care NOT created', '', 'info');
      }
    });
  }

  calculateAge(dateString: string): number | undefined {
    const birthDate = new Date(dateString);
    const currentDate = new Date();

    const yearsDiff = currentDate.getFullYear() - birthDate.getFullYear();

    // Check if the birthday has occurred this year
    if (
      currentDate.getMonth() < birthDate.getMonth() ||
      (currentDate.getMonth() === birthDate.getMonth() &&
        currentDate.getDate() < birthDate.getDate())
    ) {
      return yearsDiff - 1;
    } else {
      return yearsDiff;
    }
  }

  discardVisit() {
    Swal.fire({
      text: 'Are you sure that you want to discard this visit?',
      showDenyButton: false,
      showCancelButton: true,
      confirmButtonText: 'Ok',
    }).then((result) => {
      if (result.isConfirmed) {
        this.eocService
          .deleteVisit(this.eocService.currentVisitId())
          .subscribe({
            next: () => {
              if (this.isInpatientView()) {
                this.patientService.patientIDsignal.set('');
                this.patientService.patientInfoSub.next({});
                this.eocService.currentVisitId.set('');
                this.eocService.currentEocId.set('');
                this.router.navigate(['/ehr/inPatients']);
              } else {
                this.router.navigate(['/ehr/profile'], {
                  queryParams: {
                    patientID: this.patientService.patientIDsignal(),
                  },
                });
              }

              Swal.fire({
                text: 'Visit was deleted successfully!',
                toast: true,
                position: 'bottom-end',
                showCancelButton: false,
                showConfirmButton: false,
                color: 'white',
                background: '#0d9488',
                timer: 3000,
              });
            },
            error: () => {
              Swal.fire({
                text: 'Unable to delete the visit!',
                toast: true,
                position: 'bottom-end',
                showCancelButton: false,
                showConfirmButton: false,
                color: 'white',
                background: '#ff6969',
                timer: 3000,
              });
            },
          });
      } else if (result.isDenied) {
        Swal.fire('Changes are not saved', '', 'info');
      }
    });
  }

  saveVisit() {
    Swal.fire({
      text: 'Are you sure that you want to save this visit?',
      showDenyButton: false,
      showCancelButton: true,
      confirmButtonText: 'Ok',
    }).then((result) => {
      if (result.isConfirmed) {
        this.eocService.saveVisit().subscribe({
          next: (response) => {
            if (this.isInpatientView()) {
              this.patientService.patientIDsignal.set('');
              this.patientService.patientInfoSub.next({});
              this.eocService.currentVisitId.set('');
              this.eocService.currentEocId.set('');
              this.router.navigate(['/ehr/inPatients']);
            } else {
              this.router.navigate(['/ehr/profile'], {
                queryParams: {
                  patientID: this.patientService.patientIDsignal(),
                },
              });
            }

            Swal.fire({
              text: 'Visit was saved successfully!',
              toast: true,
              position: 'bottom-end',
              showCancelButton: false,
              showConfirmButton: false,
              color: 'white',
              background: '#0d9488',
              timer: 3000,
            });
          },
          error: (err) => {
            Swal.fire({
              text: 'Unable to save the visit!',
              toast: true,
              position: 'bottom-end',
              showCancelButton: false,
              showConfirmButton: false,
              color: 'white',
              background: '#ff6969',
              timer: 3000,
            });

            console.log(err);
          },
        });
      } else if (result.isDenied) {
        Swal.fire('Cannot save the visit', '', 'info');
      }
    });
  }
}
